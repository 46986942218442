/*! Bootstrap 4 "Charming" Theme by HackerThemes, https://hackerthemes.com */
/*!
 * Bootstrap v4.1.0 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #fa2840;
  --orange: #fba91e;
  --yellow: #ffc107;
  --green: #35bd49;
  --teal: #20c997;
  --cyan: #4ac1ee;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #48577D;
  --secondary: #9197A6;
  --success: #1CA336;
  --info: #8DCCF5;
  --warning: #F59F3F;
  --danger: #DC210D;
  --light: #D3DCF2;
  --dark: #43464C;
  --accent-1: #7690CF;
  --accent-2: #43464C;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Lato", Arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Lato", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #373a3c;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #7690CF;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #4063b8;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.4375rem; }

h2, .h2 {
  font-size: 1.9375rem; }

h3, .h3 {
  font-size: 1.5625rem; }

h4, .h4 {
  font-size: 1.25rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 5.9375rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 4.75rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 3.8125rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.0625rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 5px; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ccd0db; }

.table-hover .table-primary:hover {
  background-color: #bdc2d0; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #bdc2d0; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #e0e2e6; }

.table-hover .table-secondary:hover {
  background-color: #d2d5db; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #d2d5db; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #bfe5c7; }

.table-hover .table-success:hover {
  background-color: #addeb7; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #addeb7; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #dff1fc; }

.table-hover .table-info:hover {
  background-color: #c8e7fa; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #c8e7fa; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fce4c9; }

.table-hover .table-warning:hover {
  background-color: #fbd8b1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fbd8b1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c1bb; }

.table-hover .table-danger:hover {
  background-color: #f2ada5; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f2ada5; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f3f5fb; }

.table-hover .table-light:hover {
  background-color: #e0e5f5; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #e0e5f5; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #cacbcd; }

.table-hover .table-dark:hover {
  background-color: #bdbec1; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #bdbec1; }

.table-accent-1,
.table-accent-1 > th,
.table-accent-1 > td {
  background-color: #d9e0f2; }

.table-hover .table-accent-1:hover {
  background-color: #c6d1ec; }
  .table-hover .table-accent-1:hover > td,
  .table-hover .table-accent-1:hover > th {
    background-color: #c6d1ec; }

.table-accent-2,
.table-accent-2 > th,
.table-accent-2 > td {
  background-color: #cacbcd; }

.table-hover .table-accent-2:hover {
  background-color: #bdbec1; }
  .table-hover .table-accent-2:hover > td,
  .table-hover .table-accent-2:hover > th {
    background-color: #bdbec1; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #898989;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(72, 87, 125, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.44; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #373a3c;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 5px; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.44;
  border-radius: 5px; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.8rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #1CA336; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(28, 163, 54, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #1CA336; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #1CA336;
    box-shadow: 0 0 0 0.2rem rgba(28, 163, 54, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #1CA336; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #1CA336; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #5ce376; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #23cf44; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(28, 163, 54, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #1CA336; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(28, 163, 54, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #DC210D; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 33, 13, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #DC210D; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #DC210D;
    box-shadow: 0 0 0 0.2rem rgba(220, 33, 13, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #DC210D; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #DC210D; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #f77f72; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #f23d2a; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 33, 13, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #DC210D; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 33, 13, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 10px 0.75rem;
  font-size: 1rem;
  line-height: 1;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(72, 87, 125, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #48577D;
  border-color: #48577D; }
  .btn-primary:hover {
    color: #fff;
    background-color: #3a4665;
    border-color: #35405d; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 87, 125, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #48577D;
    border-color: #48577D; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #35405d;
    border-color: #313b55; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 87, 125, 0.5); }

.btn-secondary {
  color: #212529;
  background-color: #9197A6;
  border-color: #9197A6; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #7c8395;
    border-color: #757c8f; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(145, 151, 166, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #9197A6;
    border-color: #9197A6; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #757c8f;
    border-color: #6f7689; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(145, 151, 166, 0.5); }

.btn-success {
  color: #fff;
  background-color: #1CA336;
  border-color: #1CA336; }
  .btn-success:hover {
    color: #fff;
    background-color: #16822b;
    border-color: #157728; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(28, 163, 54, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #1CA336;
    border-color: #1CA336; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #157728;
    border-color: #136d24; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(28, 163, 54, 0.5); }

.btn-info {
  color: #212529;
  background-color: #8DCCF5;
  border-color: #8DCCF5; }
  .btn-info:hover {
    color: #212529;
    background-color: #6abcf2;
    border-color: #5eb7f1; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(141, 204, 245, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #212529;
    background-color: #8DCCF5;
    border-color: #8DCCF5; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #212529;
    background-color: #5eb7f1;
    border-color: #52b2f0; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(141, 204, 245, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #F59F3F;
  border-color: #F59F3F; }
  .btn-warning:hover {
    color: #212529;
    background-color: #f38d1b;
    border-color: #f2870f; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 159, 63, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #F59F3F;
    border-color: #F59F3F; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #f2870f;
    border-color: #e8800c; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 159, 63, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #DC210D;
  border-color: #DC210D; }
  .btn-danger:hover {
    color: #fff;
    background-color: #b81c0b;
    border-color: #ac1a0a; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 33, 13, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #DC210D;
    border-color: #DC210D; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ac1a0a;
    border-color: #a01809; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 33, 13, 0.5); }

.btn-light {
  color: #212529;
  background-color: #D3DCF2;
  border-color: #D3DCF2; }
  .btn-light:hover {
    color: #212529;
    background-color: #b5c5e9;
    border-color: #acbde6; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(211, 220, 242, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #D3DCF2;
    border-color: #D3DCF2; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #acbde6;
    border-color: #a2b5e3; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(211, 220, 242, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #43464C;
  border-color: #43464C; }
  .btn-dark:hover {
    color: #fff;
    background-color: #313338;
    border-color: #2b2d31; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(67, 70, 76, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #43464C;
    border-color: #43464C; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #2b2d31;
    border-color: #25272a; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(67, 70, 76, 0.5); }

.btn-accent-1 {
  color: #fff;
  background-color: #7690CF;
  border-color: #7690CF; }
  .btn-accent-1:hover {
    color: #fff;
    background-color: #5a79c5;
    border-color: #5071c2; }
  .btn-accent-1:focus, .btn-accent-1.focus {
    box-shadow: 0 0 0 0.2rem rgba(118, 144, 207, 0.5); }
  .btn-accent-1.disabled, .btn-accent-1:disabled {
    color: #fff;
    background-color: #7690CF;
    border-color: #7690CF; }
  .btn-accent-1:not(:disabled):not(.disabled):active, .btn-accent-1:not(:disabled):not(.disabled).active,
  .show > .btn-accent-1.dropdown-toggle {
    color: #fff;
    background-color: #5071c2;
    border-color: #476abe; }
    .btn-accent-1:not(:disabled):not(.disabled):active:focus, .btn-accent-1:not(:disabled):not(.disabled).active:focus,
    .show > .btn-accent-1.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(118, 144, 207, 0.5); }

.btn-accent-2 {
  color: #fff;
  background-color: #43464C;
  border-color: #43464C; }
  .btn-accent-2:hover {
    color: #fff;
    background-color: #313338;
    border-color: #2b2d31; }
  .btn-accent-2:focus, .btn-accent-2.focus {
    box-shadow: 0 0 0 0.2rem rgba(67, 70, 76, 0.5); }
  .btn-accent-2.disabled, .btn-accent-2:disabled {
    color: #fff;
    background-color: #43464C;
    border-color: #43464C; }
  .btn-accent-2:not(:disabled):not(.disabled):active, .btn-accent-2:not(:disabled):not(.disabled).active,
  .show > .btn-accent-2.dropdown-toggle {
    color: #fff;
    background-color: #2b2d31;
    border-color: #25272a; }
    .btn-accent-2:not(:disabled):not(.disabled):active:focus, .btn-accent-2:not(:disabled):not(.disabled).active:focus,
    .show > .btn-accent-2.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(67, 70, 76, 0.5); }

.btn-outline-primary {
  color: #48577D;
  background-color: transparent;
  background-image: none;
  border-color: #48577D; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #48577D;
    border-color: #48577D; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 87, 125, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #48577D;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #48577D;
    border-color: #48577D; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 87, 125, 0.5); }

.btn-outline-secondary {
  color: #9197A6;
  background-color: transparent;
  background-image: none;
  border-color: #9197A6; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #9197A6;
    border-color: #9197A6; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(145, 151, 166, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #9197A6;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #9197A6;
    border-color: #9197A6; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(145, 151, 166, 0.5); }

.btn-outline-success {
  color: #1CA336;
  background-color: transparent;
  background-image: none;
  border-color: #1CA336; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #1CA336;
    border-color: #1CA336; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(28, 163, 54, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #1CA336;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #1CA336;
    border-color: #1CA336; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(28, 163, 54, 0.5); }

.btn-outline-info {
  color: #8DCCF5;
  background-color: transparent;
  background-image: none;
  border-color: #8DCCF5; }
  .btn-outline-info:hover {
    color: #212529;
    background-color: #8DCCF5;
    border-color: #8DCCF5; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(141, 204, 245, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #8DCCF5;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #212529;
    background-color: #8DCCF5;
    border-color: #8DCCF5; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(141, 204, 245, 0.5); }

.btn-outline-warning {
  color: #F59F3F;
  background-color: transparent;
  background-image: none;
  border-color: #F59F3F; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #F59F3F;
    border-color: #F59F3F; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 159, 63, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #F59F3F;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #F59F3F;
    border-color: #F59F3F; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 159, 63, 0.5); }

.btn-outline-danger {
  color: #DC210D;
  background-color: transparent;
  background-image: none;
  border-color: #DC210D; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #DC210D;
    border-color: #DC210D; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 33, 13, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #DC210D;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #DC210D;
    border-color: #DC210D; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 33, 13, 0.5); }

.btn-outline-light {
  color: #D3DCF2;
  background-color: transparent;
  background-image: none;
  border-color: #D3DCF2; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #D3DCF2;
    border-color: #D3DCF2; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(211, 220, 242, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #D3DCF2;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #D3DCF2;
    border-color: #D3DCF2; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(211, 220, 242, 0.5); }

.btn-outline-dark {
  color: #43464C;
  background-color: transparent;
  background-image: none;
  border-color: #43464C; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #43464C;
    border-color: #43464C; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(67, 70, 76, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #43464C;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #43464C;
    border-color: #43464C; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(67, 70, 76, 0.5); }

.btn-outline-accent-1 {
  color: #7690CF;
  background-color: transparent;
  background-image: none;
  border-color: #7690CF; }
  .btn-outline-accent-1:hover {
    color: #fff;
    background-color: #7690CF;
    border-color: #7690CF; }
  .btn-outline-accent-1:focus, .btn-outline-accent-1.focus {
    box-shadow: 0 0 0 0.2rem rgba(118, 144, 207, 0.5); }
  .btn-outline-accent-1.disabled, .btn-outline-accent-1:disabled {
    color: #7690CF;
    background-color: transparent; }
  .btn-outline-accent-1:not(:disabled):not(.disabled):active, .btn-outline-accent-1:not(:disabled):not(.disabled).active,
  .show > .btn-outline-accent-1.dropdown-toggle {
    color: #fff;
    background-color: #7690CF;
    border-color: #7690CF; }
    .btn-outline-accent-1:not(:disabled):not(.disabled):active:focus, .btn-outline-accent-1:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-accent-1.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(118, 144, 207, 0.5); }

.btn-outline-accent-2 {
  color: #43464C;
  background-color: transparent;
  background-image: none;
  border-color: #43464C; }
  .btn-outline-accent-2:hover {
    color: #fff;
    background-color: #43464C;
    border-color: #43464C; }
  .btn-outline-accent-2:focus, .btn-outline-accent-2.focus {
    box-shadow: 0 0 0 0.2rem rgba(67, 70, 76, 0.5); }
  .btn-outline-accent-2.disabled, .btn-outline-accent-2:disabled {
    color: #43464C;
    background-color: transparent; }
  .btn-outline-accent-2:not(:disabled):not(.disabled):active, .btn-outline-accent-2:not(:disabled):not(.disabled).active,
  .show > .btn-outline-accent-2.dropdown-toggle {
    color: #fff;
    background-color: #43464C;
    border-color: #43464C; }
    .btn-outline-accent-2:not(:disabled):not(.disabled):active:focus, .btn-outline-accent-2:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-accent-2.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(67, 70, 76, 0.5); }

.btn-link {
  font-weight: 400;
  color: #7690CF;
  background-color: transparent; }
  .btn-link:hover {
    color: #4063b8;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.44;
  border-radius: 5px; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 5px; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #373a3c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #48577D; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label::after {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 5px; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #494949; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem #494949; }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #aab4ce; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 5px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #494949; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #494949; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(72, 87, 125, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(72, 87, 125, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #494949; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(72, 87, 125, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  appearance: none; }
  .custom-select:focus {
    border-color: #898989;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(137, 137, 137, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.8rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #898989;
    box-shadow: 0 0 0 0.2rem rgba(72, 87, 125, 0.25); }
    .custom-file-input:focus ~ .custom-file-label::after {
      border-color: #898989; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 5px 5px 0; }

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #48577D;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-webkit-slider-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(72, 87, 125, 0.25); }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #aab4ce; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #48577D;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-moz-range-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(72, 87, 125, 0.25); }
    .custom-range::-moz-range-thumb:active {
      background-color: #aab4ce; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #48577D;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-ms-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(72, 87, 125, 0.25); }
    .custom-range::-ms-thumb:active {
      background-color: #aab4ce; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 5px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #48577D; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: #4c4c4c; }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: #4c4c4c; }

.navbar-light .navbar-nav .nav-link {
  color: #343434; }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #4c4c4c; }

.navbar-light .navbar-toggler {
  color: #343434;
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23343434' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: #343434; }
  .navbar-light .navbar-text a {
    color: #4c4c4c; }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: #4c4c4c; }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.9); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.95); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: #fff; }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.9);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.9); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(5px - 1px) calc(5px - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(5px - 1px) calc(5px - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(5px - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(5px - 1px);
  border-top-right-radius: calc(5px - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(5px - 1px);
  border-bottom-left-radius: calc(5px - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 5px; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0; }

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0; }

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 5px; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 5px; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #7690CF;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #4063b8;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(72, 87, 125, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

.page-item:last-child .page-link {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #48577D;
  border-color: #48577D; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.44; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 5px; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #48577D; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #35405d; }

.badge-secondary {
  color: #212529;
  background-color: #9197A6; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #757c8f; }

.badge-success {
  color: #fff;
  background-color: #1CA336; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #157728; }

.badge-info {
  color: #212529;
  background-color: #8DCCF5; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #5eb7f1; }

.badge-warning {
  color: #212529;
  background-color: #F59F3F; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #f2870f; }

.badge-danger {
  color: #fff;
  background-color: #DC210D; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #ac1a0a; }

.badge-light {
  color: #212529;
  background-color: #D3DCF2; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #acbde6; }

.badge-dark {
  color: #fff;
  background-color: #43464C; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #2b2d31; }

.badge-accent-1 {
  color: #fff;
  background-color: #7690CF; }
  .badge-accent-1[href]:hover, .badge-accent-1[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #5071c2; }

.badge-accent-2 {
  color: #fff;
  background-color: #43464C; }
  .badge-accent-2[href]:hover, .badge-accent-2[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #2b2d31; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 5px; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 5px; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #252d41;
  background-color: #dadde5;
  border-color: #ccd0db; }
  .alert-primary hr {
    border-top-color: #bdc2d0; }
  .alert-primary .alert-link {
    color: #131721; }

.alert-secondary {
  color: #4b4f56;
  background-color: #e9eaed;
  border-color: #e0e2e6; }
  .alert-secondary hr {
    border-top-color: #d2d5db; }
  .alert-secondary .alert-link {
    color: #33363b; }

.alert-success {
  color: #0f551c;
  background-color: #d2edd7;
  border-color: #bfe5c7; }
  .alert-success hr {
    border-top-color: #addeb7; }
  .alert-success .alert-link {
    color: #072a0e; }

.alert-info {
  color: #496a7f;
  background-color: #e8f5fd;
  border-color: #dff1fc; }
  .alert-info hr {
    border-top-color: #c8e7fa; }
  .alert-info .alert-link {
    color: #364f5f; }

.alert-warning {
  color: #7f5321;
  background-color: #fdecd9;
  border-color: #fce4c9; }
  .alert-warning hr {
    border-top-color: #fbd8b1; }
  .alert-warning .alert-link {
    color: #573916; }

.alert-danger {
  color: #721107;
  background-color: #f8d3cf;
  border-color: #f5c1bb; }
  .alert-danger hr {
    border-top-color: #f2ada5; }
  .alert-danger .alert-link {
    color: #420a04; }

.alert-light {
  color: #6e727e;
  background-color: #f6f8fc;
  border-color: #f3f5fb; }
  .alert-light hr {
    border-top-color: #e0e5f5; }
  .alert-light .alert-link {
    color: #565963; }

.alert-dark {
  color: #232428;
  background-color: #d9dadb;
  border-color: #cacbcd; }
  .alert-dark hr {
    border-top-color: #bdbec1; }
  .alert-dark .alert-link {
    color: #0b0c0d; }

.alert-accent-1 {
  color: #3d4b6c;
  background-color: #e4e9f5;
  border-color: #d9e0f2; }
  .alert-accent-1 hr {
    border-top-color: #c6d1ec; }
  .alert-accent-1 .alert-link {
    color: #2b344b; }

.alert-accent-2 {
  color: #232428;
  background-color: #d9dadb;
  border-color: #cacbcd; }
  .alert-accent-2 hr {
    border-top-color: #bdbec1; }
  .alert-accent-2 .alert-link {
    color: #0b0c0d; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 5px; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #48577D;
  transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #373a3c;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #48577D;
    border-color: #48577D; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #252d41;
  background-color: #ccd0db; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #252d41;
    background-color: #bdc2d0; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #252d41;
    border-color: #252d41; }

.list-group-item-secondary {
  color: #4b4f56;
  background-color: #e0e2e6; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #4b4f56;
    background-color: #d2d5db; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #4b4f56;
    border-color: #4b4f56; }

.list-group-item-success {
  color: #0f551c;
  background-color: #bfe5c7; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #0f551c;
    background-color: #addeb7; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0f551c;
    border-color: #0f551c; }

.list-group-item-info {
  color: #496a7f;
  background-color: #dff1fc; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #496a7f;
    background-color: #c8e7fa; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #496a7f;
    border-color: #496a7f; }

.list-group-item-warning {
  color: #7f5321;
  background-color: #fce4c9; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #7f5321;
    background-color: #fbd8b1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #7f5321;
    border-color: #7f5321; }

.list-group-item-danger {
  color: #721107;
  background-color: #f5c1bb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721107;
    background-color: #f2ada5; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721107;
    border-color: #721107; }

.list-group-item-light {
  color: #6e727e;
  background-color: #f3f5fb; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #6e727e;
    background-color: #e0e5f5; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #6e727e;
    border-color: #6e727e; }

.list-group-item-dark {
  color: #232428;
  background-color: #cacbcd; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #232428;
    background-color: #bdbec1; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #232428;
    border-color: #232428; }

.list-group-item-accent-1 {
  color: #3d4b6c;
  background-color: #d9e0f2; }
  .list-group-item-accent-1.list-group-item-action:hover, .list-group-item-accent-1.list-group-item-action:focus {
    color: #3d4b6c;
    background-color: #c6d1ec; }
  .list-group-item-accent-1.list-group-item-action.active {
    color: #fff;
    background-color: #3d4b6c;
    border-color: #3d4b6c; }

.list-group-item-accent-2 {
  color: #232428;
  background-color: #cacbcd; }
  .list-group-item-accent-2.list-group-item-action:hover, .list-group-item-accent-2.list-group-item-action:focus {
    color: #232428;
    background-color: #bdbec1; }
  .list-group-item-accent-2.list-group-item-action.active {
    color: #fff;
    background-color: #232428;
    border-color: #232428; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Lato", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 5px; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Lato", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 5px; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 5px 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 5px 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(5px - 1px);
  border-top-right-radius: calc(5px - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #373a3c; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0; }

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
      transform: translate3d(0, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #48577D !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #35405d !important; }

.bg-secondary {
  background-color: #9197A6 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #757c8f !important; }

.bg-success {
  background-color: #1CA336 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #157728 !important; }

.bg-info {
  background-color: #8DCCF5 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #5eb7f1 !important; }

.bg-warning {
  background-color: #F59F3F !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f2870f !important; }

.bg-danger {
  background-color: #DC210D !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ac1a0a !important; }

.bg-light {
  background-color: #D3DCF2 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #acbde6 !important; }

.bg-dark {
  background-color: #43464C !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #2b2d31 !important; }

.bg-accent-1 {
  background-color: #7690CF !important; }

a.bg-accent-1:hover, a.bg-accent-1:focus,
button.bg-accent-1:hover,
button.bg-accent-1:focus {
  background-color: #5071c2 !important; }

.bg-accent-2 {
  background-color: #43464C !important; }

a.bg-accent-2:hover, a.bg-accent-2:focus,
button.bg-accent-2:hover,
button.bg-accent-2:focus {
  background-color: #2b2d31 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #48577D !important; }

.border-secondary {
  border-color: #9197A6 !important; }

.border-success {
  border-color: #1CA336 !important; }

.border-info {
  border-color: #8DCCF5 !important; }

.border-warning {
  border-color: #F59F3F !important; }

.border-danger {
  border-color: #DC210D !important; }

.border-light {
  border-color: #D3DCF2 !important; }

.border-dark {
  border-color: #43464C !important; }

.border-accent-1 {
  border-color: #7690CF !important; }

.border-accent-2 {
  border-color: #43464C !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 5px !important; }

.rounded-top {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important; }

.rounded-right {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important; }

.rounded-bottom {
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important; }

.rounded-left {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.m-6 {
  margin: 3.75rem !important; }

.mt-6,
.my-6 {
  margin-top: 3.75rem !important; }

.mr-6,
.mx-6 {
  margin-right: 3.75rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 3.75rem !important; }

.ml-6,
.mx-6 {
  margin-left: 3.75rem !important; }

.m-7 {
  margin: 4.5rem !important; }

.mt-7,
.my-7 {
  margin-top: 4.5rem !important; }

.mr-7,
.mx-7 {
  margin-right: 4.5rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 4.5rem !important; }

.ml-7,
.mx-7 {
  margin-left: 4.5rem !important; }

.m-8 {
  margin: 6rem !important; }

.mt-8,
.my-8 {
  margin-top: 6rem !important; }

.mr-8,
.mx-8 {
  margin-right: 6rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 6rem !important; }

.ml-8,
.mx-8 {
  margin-left: 6rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.p-6 {
  padding: 3.75rem !important; }

.pt-6,
.py-6 {
  padding-top: 3.75rem !important; }

.pr-6,
.px-6 {
  padding-right: 3.75rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 3.75rem !important; }

.pl-6,
.px-6 {
  padding-left: 3.75rem !important; }

.p-7 {
  padding: 4.5rem !important; }

.pt-7,
.py-7 {
  padding-top: 4.5rem !important; }

.pr-7,
.px-7 {
  padding-right: 4.5rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 4.5rem !important; }

.pl-7,
.px-7 {
  padding-left: 4.5rem !important; }

.p-8 {
  padding: 6rem !important; }

.pt-8,
.py-8 {
  padding-top: 6rem !important; }

.pr-8,
.px-8 {
  padding-right: 6rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 6rem !important; }

.pl-8,
.px-8 {
  padding-left: 6rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .m-sm-6 {
    margin: 3.75rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 3.75rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 3.75rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 3.75rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 3.75rem !important; }
  .m-sm-7 {
    margin: 4.5rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 4.5rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 4.5rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 4.5rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 4.5rem !important; }
  .m-sm-8 {
    margin: 6rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 6rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 6rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 6rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 6rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .p-sm-6 {
    padding: 3.75rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 3.75rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 3.75rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 3.75rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 3.75rem !important; }
  .p-sm-7 {
    padding: 4.5rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 4.5rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 4.5rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 4.5rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 4.5rem !important; }
  .p-sm-8 {
    padding: 6rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 6rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 6rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 6rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 6rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .m-md-6 {
    margin: 3.75rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 3.75rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 3.75rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 3.75rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 3.75rem !important; }
  .m-md-7 {
    margin: 4.5rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 4.5rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 4.5rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 4.5rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 4.5rem !important; }
  .m-md-8 {
    margin: 6rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 6rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 6rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 6rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 6rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .p-md-6 {
    padding: 3.75rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 3.75rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 3.75rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 3.75rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 3.75rem !important; }
  .p-md-7 {
    padding: 4.5rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 4.5rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 4.5rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 4.5rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 4.5rem !important; }
  .p-md-8 {
    padding: 6rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 6rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 6rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 6rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 6rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .m-lg-6 {
    margin: 3.75rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 3.75rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 3.75rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 3.75rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 3.75rem !important; }
  .m-lg-7 {
    margin: 4.5rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 4.5rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 4.5rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 4.5rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 4.5rem !important; }
  .m-lg-8 {
    margin: 6rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 6rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 6rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 6rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 6rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .p-lg-6 {
    padding: 3.75rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 3.75rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 3.75rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 3.75rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 3.75rem !important; }
  .p-lg-7 {
    padding: 4.5rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 4.5rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 4.5rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 4.5rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 4.5rem !important; }
  .p-lg-8 {
    padding: 6rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 6rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 6rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 6rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 6rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .m-xl-6 {
    margin: 3.75rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 3.75rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 3.75rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 3.75rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 3.75rem !important; }
  .m-xl-7 {
    margin: 4.5rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 4.5rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 4.5rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 4.5rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 4.5rem !important; }
  .m-xl-8 {
    margin: 6rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 6rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 6rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 6rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 6rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .p-xl-6 {
    padding: 3.75rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 3.75rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 3.75rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 3.75rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 3.75rem !important; }
  .p-xl-7 {
    padding: 4.5rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 4.5rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 4.5rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 4.5rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 4.5rem !important; }
  .p-xl-8 {
    padding: 6rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 6rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 6rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 6rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 6rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #48577D !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #35405d !important; }

.text-secondary {
  color: #9197A6 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #757c8f !important; }

.text-success {
  color: #1CA336 !important; }

a.text-success:hover, a.text-success:focus {
  color: #157728 !important; }

.text-info {
  color: #8DCCF5 !important; }

a.text-info:hover, a.text-info:focus {
  color: #5eb7f1 !important; }

.text-warning {
  color: #F59F3F !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #f2870f !important; }

.text-danger {
  color: #DC210D !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #ac1a0a !important; }

.text-light {
  color: #D3DCF2 !important; }

a.text-light:hover, a.text-light:focus {
  color: #acbde6 !important; }

.text-dark {
  color: #43464C !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #2b2d31 !important; }

.text-accent-1 {
  color: #7690CF !important; }

a.text-accent-1:hover, a.text-accent-1:focus {
  color: #5071c2 !important; }

.text-accent-2 {
  color: #43464C !important; }

a.text-accent-2:hover, a.text-accent-2:focus {
  color: #2b2d31 !important; }

.text-body {
  color: #373a3c !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; } }

.btn-hover-text-primary:hover {
  color: #48577D; }

.btn-hover-text-secondary:hover {
  color: #9197A6; }

.btn-hover-text-success:hover {
  color: #1CA336; }

.btn-hover-text-info:hover {
  color: #8DCCF5; }

.btn-hover-text-warning:hover {
  color: #F59F3F; }

.btn-hover-text-danger:hover {
  color: #DC210D; }

.btn-hover-text-light:hover {
  color: #D3DCF2; }

.btn-hover-text-dark:hover {
  color: #43464C; }

.btn-hover-text-accent-1:hover {
  color: #7690CF; }

.btn-hover-text-accent-2:hover {
  color: #43464C; }

.btn-wide {
  padding-left: 50px;
  padding-right: 50px; }

.display-fix {
  margin-left: -4px; }

.btn-pill {
  border-radius: 100px; }

.lead-lg {
  font-size: 35px; }

.alert-primary {
  color: #202637;
  background-color: #919ab1;
  border-color: #657292; }
  .alert-primary hr {
    border-top-color: #5b6683; }
  .alert-primary .alert-link {
    color: #0d1017; }

.alert-secondary {
  color: #404249;
  background-color: #bdc1ca;
  border-color: #a3a8b4; }
  .alert-secondary hr {
    border-top-color: #959ba9; }
  .alert-secondary .alert-link {
    color: #28292e; }

.alert-success {
  color: #0c4818;
  background-color: #77c886;
  border-color: #40b256; }
  .alert-success hr {
    border-top-color: #399f4d; }
  .alert-success .alert-link {
    color: #051c09; }

.alert-info {
  color: #3e5a6c;
  background-color: #bbe0f9;
  border-color: #9fd4f7; }
  .alert-info hr {
    border-top-color: #87c9f5; }
  .alert-info .alert-link {
    color: #2b3f4c; }

.alert-warning {
  color: #6c461c;
  background-color: #f9c58c;
  border-color: #f7ae5e; }
  .alert-warning hr {
    border-top-color: #f6a246; }
  .alert-warning .alert-link {
    color: #442c12; }

.alert-danger {
  color: #610f06;
  background-color: #ea7a6e;
  border-color: #e24534; }
  .alert-danger hr {
    border-top-color: #dd3220; }
  .alert-danger .alert-link {
    color: #310803; }

.alert-light {
  color: #5d616a;
  background-color: #e5eaf7;
  border-color: #dae2f4; }
  .alert-light hr {
    border-top-color: #c6d3ee; }
  .alert-light .alert-link {
    color: #45484f; }

.alert-dark {
  color: #1d1f21;
  background-color: #8e9094;
  border-color: #616469; }
  .alert-dark hr {
    border-top-color: #55575c; }
  .alert-dark .alert-link {
    color: #050606; }

.alert-accent-1 {
  color: #343f5b;
  background-color: #adbce2;
  border-color: #8ca2d7; }
  .alert-accent-1 hr {
    border-top-color: #7993d0; }
  .alert-accent-1 .alert-link {
    color: #21293b; }

.alert-accent-2 {
  color: #1d1f21;
  background-color: #8e9094;
  border-color: #616469; }
  .alert-accent-2 hr {
    border-top-color: #55575c; }
  .alert-accent-2 .alert-link {
    color: #050606; }

.btn-xs {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 16px;
  border-radius: 5px; }

.btn-md {
  padding: 1rem 2rem;
  font-size: 1rem;
  line-height: 16px;
  border-radius: 5px; }

.btn-lg, .btn-group-lg > .btn {
  padding: 1rem 2rem;
  font-size: 1.25rem;
  line-height: 16px;
  border-radius: 5px; }

.btn-primary {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 0 rgba(39, 39, 39, 0.22), 0 1px 3px 0 rgba(0, 0, 0, 0.22);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.07); }
  .btn-primary.btn-lg, .btn-group-lg > .btn-primary.btn {
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3), inset 0 -2px 0 0 rgba(39, 39, 39, 0.22), 0 1px 3px 0 rgba(0, 0, 0, 0.22); }

.btn-secondary {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 0 rgba(39, 39, 39, 0.22), 0 1px 3px 0 rgba(0, 0, 0, 0.22);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.07); }
  .btn-secondary.btn-lg, .btn-group-lg > .btn-secondary.btn {
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3), inset 0 -2px 0 0 rgba(39, 39, 39, 0.22), 0 1px 3px 0 rgba(0, 0, 0, 0.22); }

.btn-success {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 0 rgba(39, 39, 39, 0.22), 0 1px 3px 0 rgba(0, 0, 0, 0.22);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.07); }
  .btn-success.btn-lg, .btn-group-lg > .btn-success.btn {
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3), inset 0 -2px 0 0 rgba(39, 39, 39, 0.22), 0 1px 3px 0 rgba(0, 0, 0, 0.22); }

.btn-info {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 0 rgba(39, 39, 39, 0.22), 0 1px 3px 0 rgba(0, 0, 0, 0.22);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.07); }
  .btn-info.btn-lg, .btn-group-lg > .btn-info.btn {
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3), inset 0 -2px 0 0 rgba(39, 39, 39, 0.22), 0 1px 3px 0 rgba(0, 0, 0, 0.22); }

.btn-warning {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 0 rgba(39, 39, 39, 0.22), 0 1px 3px 0 rgba(0, 0, 0, 0.22);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.07); }
  .btn-warning.btn-lg, .btn-group-lg > .btn-warning.btn {
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3), inset 0 -2px 0 0 rgba(39, 39, 39, 0.22), 0 1px 3px 0 rgba(0, 0, 0, 0.22); }

.btn-danger {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 0 rgba(39, 39, 39, 0.22), 0 1px 3px 0 rgba(0, 0, 0, 0.22);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.07); }
  .btn-danger.btn-lg, .btn-group-lg > .btn-danger.btn {
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3), inset 0 -2px 0 0 rgba(39, 39, 39, 0.22), 0 1px 3px 0 rgba(0, 0, 0, 0.22); }

.btn-light {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 0 rgba(39, 39, 39, 0.22), 0 1px 3px 0 rgba(0, 0, 0, 0.22);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.07); }
  .btn-light.btn-lg, .btn-group-lg > .btn-light.btn {
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3), inset 0 -2px 0 0 rgba(39, 39, 39, 0.22), 0 1px 3px 0 rgba(0, 0, 0, 0.22); }

.btn-dark {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 0 rgba(39, 39, 39, 0.22), 0 1px 3px 0 rgba(0, 0, 0, 0.22);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.07); }
  .btn-dark.btn-lg, .btn-group-lg > .btn-dark.btn {
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3), inset 0 -2px 0 0 rgba(39, 39, 39, 0.22), 0 1px 3px 0 rgba(0, 0, 0, 0.22); }

.btn-accent-1 {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 0 rgba(39, 39, 39, 0.22), 0 1px 3px 0 rgba(0, 0, 0, 0.22);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.07); }
  .btn-accent-1.btn-lg, .btn-group-lg > .btn-accent-1.btn {
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3), inset 0 -2px 0 0 rgba(39, 39, 39, 0.22), 0 1px 3px 0 rgba(0, 0, 0, 0.22); }

.btn-accent-2 {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 0 rgba(39, 39, 39, 0.22), 0 1px 3px 0 rgba(0, 0, 0, 0.22);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.07); }
  .btn-accent-2.btn-lg, .btn-group-lg > .btn-accent-2.btn {
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3), inset 0 -2px 0 0 rgba(39, 39, 39, 0.22), 0 1px 3px 0 rgba(0, 0, 0, 0.22); }

.btn-light {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.8), inset 0 -1px 0 0 rgba(39, 39, 39, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.05); }
  .btn-light.btn-lg, .btn-group-lg > .btn-light.btn {
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.8), inset 0 -2px 0 0 rgba(39, 39, 39, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.05); }

.btn-icon .icon {
  line-height: 1px;
  margin: 0;
  vertical-align: middle; }

.btn-icon .btn-icon-label {
  margin-left: 5px; }

.btn-icon.btn-lg .btn-icon-label, .btn-group-lg > .btn-icon.btn .btn-icon-label {
  margin-left: 10px; }

.charming-form-input {
  background-color: rgba(255, 255, 255, 0.8);
  border-width: 3px; }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  font-size: 1rem; }

.icon {
  font-size: 28px;
  margin: 0 5px; }

.icon-sm {
  font-size: 20px; }

.icon-xs {
  font-size: 16px; }

.icon-lg {
  font-size: 42px; }

.icon-xl {
  font-size: 72px; }

h6, .h6 {
  font-weight: bold; }

h1, .h1 {
  line-height: 1.384615385; }

h2, .h2 {
  line-height: 1.35483871; }

h3, .h3 {
  line-height: 1.44; }

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  line-height: 1.5; }

p {
  margin-bottom: 0.75rem; }

.text-soft {
  color: #828282; }

.img-simple-border {
  border: 1px #2d3842 solid; }

.icon-with-label {
  align-items: center;
  display: inline-flex;
  flex-flow: row nowrap;
  margin-right: 1.5rem; }

.icon-with-label-text {
  line-height: 20px;
  margin-left: 0.37594rem;
  margin-top: -2px; }

.link-soft {
  color: #373a3c; }
  .link-soft:hover, .link-soft:focus, .link-soft:active {
    color: #373a3c; }

/*******************
Charming PRO
*******************/
.highlight .c {
  color: #999988;
  font-style: italic; }

/* Comment */
.highlight .err {
  color: #a61717;
  background-color: #e3d2d2; }

/* Error */
.highlight .k {
  font-weight: bold; }

/* Keyword */
.highlight .o {
  font-weight: bold; }

/* Operator */
.highlight .cm {
  color: #999988;
  font-style: italic; }

/* Comment.Multiline */
.highlight .cp {
  color: #999999;
  font-weight: bold; }

/* Comment.Preproc */
.highlight .c1 {
  color: #999988;
  font-style: italic; }

/* Comment.Single */
.highlight .cs {
  color: #999999;
  font-weight: bold;
  font-style: italic; }

/* Comment.Special */
.highlight .gd {
  color: #000000;
  background-color: #ffdddd; }

/* Generic.Deleted */
.highlight .gd .x {
  color: #000000;
  background-color: #ffaaaa; }

/* Generic.Deleted.Specific */
.highlight .ge {
  font-style: italic; }

/* Generic.Emph */
.highlight .gr {
  color: #aa0000; }

/* Generic.Error */
.highlight .gh {
  color: #999999; }

/* Generic.Heading */
.highlight .gi {
  color: #000000;
  background-color: #ddffdd; }

/* Generic.Inserted */
.highlight .gi .x {
  color: #000000;
  background-color: #aaffaa; }

/* Generic.Inserted.Specific */
.highlight .go {
  color: #888888; }

/* Generic.Output */
.highlight .gp {
  color: #555555; }

/* Generic.Prompt */
.highlight .gs {
  font-weight: bold; }

/* Generic.Strong */
.highlight .gu {
  color: #aaaaaa; }

/* Generic.Subheading */
.highlight .gt {
  color: #aa0000; }

/* Generic.Traceback */
.highlight .kc {
  font-weight: bold; }

/* Keyword.Constant */
.highlight .kd {
  font-weight: bold; }

/* Keyword.Declaration */
.highlight .kp {
  font-weight: bold; }

/* Keyword.Pseudo */
.highlight .kr {
  font-weight: bold; }

/* Keyword.Reserved */
.highlight .kt {
  color: #445588;
  font-weight: bold; }

/* Keyword.Type */
.highlight .m {
  color: #009999; }

/* Literal.Number */
.highlight .s {
  color: #d14; }

/* Literal.String */
.highlight .na {
  color: #008080; }

/* Name.Attribute */
.highlight .nb {
  color: #0086B3; }

/* Name.Builtin */
.highlight .nc {
  color: #445588;
  font-weight: bold; }

/* Name.Class */
.highlight .no {
  color: #008080; }

/* Name.Constant */
.highlight .ni {
  color: #800080; }

/* Name.Entity */
.highlight .ne {
  color: #990000;
  font-weight: bold; }

/* Name.Exception */
.highlight .nf {
  color: #990000;
  font-weight: bold; }

/* Name.Function */
.highlight .nn {
  color: #555555; }

/* Name.Namespace */
.highlight .nt {
  color: #000080; }

/* Name.Tag */
.highlight .nv {
  color: #008080; }

/* Name.Variable */
.highlight .ow {
  font-weight: bold; }

/* Operator.Word */
.highlight .w {
  color: #bbbbbb; }

/* Text.Whitespace */
.highlight .mf {
  color: #009999; }

/* Literal.Number.Float */
.highlight .mh {
  color: #009999; }

/* Literal.Number.Hex */
.highlight .mi {
  color: #009999; }

/* Literal.Number.Integer */
.highlight .mo {
  color: #009999; }

/* Literal.Number.Oct */
.highlight .sb {
  color: #d14; }

/* Literal.String.Backtick */
.highlight .sc {
  color: #d14; }

/* Literal.String.Char */
.highlight .sd {
  color: #d14; }

/* Literal.String.Doc */
.highlight .s2 {
  color: #d14; }

/* Literal.String.Double */
.highlight .se {
  color: #d14; }

/* Literal.String.Escape */
.highlight .sh {
  color: #d14; }

/* Literal.String.Heredoc */
.highlight .si {
  color: #d14; }

/* Literal.String.Interpol */
.highlight .sx {
  color: #d14; }

/* Literal.String.Other */
.highlight .sr {
  color: #009926; }

/* Literal.String.Regex */
.highlight .s1 {
  color: #d14; }

/* Literal.String.Single */
.highlight .ss {
  color: #990073; }

/* Literal.String.Symbol */
.highlight .bp {
  color: #999999; }

/* Name.Builtin.Pseudo */
.highlight .vc {
  color: #008080; }

/* Name.Variable.Class */
.highlight .vg {
  color: #008080; }

/* Name.Variable.Global */
.highlight .vi {
  color: #008080; }

/* Name.Variable.Instance */
.highlight .il {
  color: #009999; }

/* Literal.Number.Integer.Long */
.box-block-codesample .highlight code, .box-block-codesample .highlight pre {
  color: #fdce93;
  background: none; }

.box-block-codesample .highlight .hll {
  background-color: #222; }

.box-block-codesample .highlight .err {
  color: #e37170;
  background-color: #3d3535; }

.box-block-codesample .highlight .k {
  color: #f0dfaf; }

.box-block-codesample .highlight .p {
  color: #41706f; }

.box-block-codesample .highlight .cs {
  color: #cd0000;
  font-weight: 700; }

.box-block-codesample .highlight .gd {
  color: #cd0000; }

.box-block-codesample .highlight .ge {
  color: #ccc;
  font-style: italic; }

.box-block-codesample .highlight .gr {
  color: red; }

.box-block-codesample .highlight .go {
  color: gray; }

.box-block-codesample .highlight .gs {
  color: #ccc;
  font-weight: 700; }

.box-block-codesample .highlight .gu {
  color: purple;
  font-weight: 700; }

.box-block-codesample .highlight .gt {
  color: #0040D0; }

.box-block-codesample .highlight .kc {
  color: #dca3a3; }

.box-block-codesample .highlight .kd {
  color: #ffff86; }

.box-block-codesample .highlight .kn {
  color: #dfaf8f;
  font-weight: 700; }

.box-block-codesample .highlight .kp {
  color: #cdcf99; }

.box-block-codesample .highlight .kr {
  color: #cdcd00; }

.box-block-codesample .highlight .ni {
  color: #c28182; }

.box-block-codesample .highlight .ne {
  color: #c3bf9f;
  font-weight: 700; }

.box-block-codesample .highlight .nn {
  color: #8fbede; }

.box-block-codesample .highlight .vi {
  color: #ffffc7; }

.box-block-codesample .highlight .c, .box-block-codesample .preview-zenburn .highlight .g, .box-block-codesample .preview-zenburn .highlight .cm, .box-block-codesample .preview-zenburn .highlight .cp, .box-block-codesample .preview-zenburn .highlight .c1 {
  color: #7f9f7f; }

.box-block-codesample .highlight .l, .box-block-codesample .preview-zenburn .highlight .x, .box-block-codesample .preview-zenburn .highlight .no, .box-block-codesample .preview-zenburn .highlight .nd, .box-block-codesample .preview-zenburn .highlight .nl, .box-block-codesample .preview-zenburn .highlight .nx, .box-block-codesample .preview-zenburn .highlight .py, .box-block-codesample .preview-zenburn .highlight .w {
  color: #ccc; }

.box-block-codesample .highlight .n, .box-block-codesample .preview-zenburn .highlight .nv, .box-block-codesample .preview-zenburn .highlight .vg {
  color: #dcdccc; }

.box-block-codesample .highlight .o, .box-block-codesample .preview-zenburn .highlight .ow {
  color: #f0efd0; }

.box-block-codesample .highlight .gh, .box-block-codesample .preview-zenburn .highlight .gp {
  color: #dcdccc;
  font-weight: 700; }

.box-block-codesample .highlight .gi, .box-block-codesample .preview-zenburn .highlight .kt {
  color: #00cd00; }

.box-block-codesample .highlight .ld, .box-block-codesample .preview-zenburn .highlight .s, .box-block-codesample .preview-zenburn .highlight .sb, .box-block-codesample .preview-zenburn .highlight .sc, .box-block-codesample .preview-zenburn .highlight .sd, .box-block-codesample .preview-zenburn .highlight .s2, .box-block-codesample .preview-zenburn .highlight .se, .box-block-codesample .preview-zenburn .highlight .sh, .box-block-codesample .preview-zenburn .highlight .si, .box-block-codesample .preview-zenburn .highlight .sx, .box-block-codesample .preview-zenburn .highlight .sr, .box-block-codesample .preview-zenburn .highlight .s1, .box-block-codesample .preview-zenburn .highlight .ss {
  color: #cc9393; }

.box-block-codesample .highlight .m, .box-block-codesample .preview-zenburn .highlight .mf, .box-block-codesample .preview-zenburn .highlight .mh, .box-block-codesample .preview-zenburn .highlight .mi, .box-block-codesample .preview-zenburn .highlight .mo, .box-block-codesample .preview-zenburn .highlight .il {
  color: #8cd0d3; }

.box-block-codesample .highlight .na, .box-block-codesample .preview-zenburn .highlight .nt {
  color: #9ac39f; }

.box-block-codesample .highlight .nb, .box-block-codesample .preview-zenburn .highlight .nc, .box-block-codesample .preview-zenburn .highlight .nf, .box-block-codesample .preview-zenburn .highlight .bp, .box-block-codesample .preview-zenburn .highlight .vc {
  color: #efef8f; }

.block {
  padding: 1.5rem 0; }
  @media (min-width: 576px) {
    .block {
      padding: 3rem 0; } }
  .container .block {
    border-radius: 5px; }

.block-bb {
  border-bottom: 2px solid #cbcdd9; }

.block-bt {
  border-top: 2px solid #cbcdd9; }

.block-heading {
  margin-bottom: 4.5rem;
  text-align: center; }

.block-title {
  font-weight: bold;
  margin-bottom: 0; }

.block-subtitle {
  margin-bottom: 0; }

.block-image {
  align-items: center;
  display: flex; }
  @media (min-width: 992px) {
    .block-image {
      height: 600px;
      max-height: 600px;
      min-height: 432px; } }

.block-primary {
  background: linear-gradient(to right, #51628d, #3f4c6d);
  background-color: #48577d;
  color: #fff; }

.block-secondary {
  background: linear-gradient(to right, #9fa4b1, #838a9b);
  background-color: #9197a6;
  color: #212529; }

.block-success {
  background: linear-gradient(to right, #20b93d, #188d2f);
  background-color: #1ca336;
  color: #fff; }

.block-info {
  background: linear-gradient(to right, #a4d6f7, #76c2f3);
  background-color: #8dccf5;
  color: #212529; }

.block-warning {
  background: linear-gradient(to right, #f6ab57, #f49327);
  background-color: #f59f3f;
  color: #212529; }

.block-danger {
  background: linear-gradient(to right, #f12712, #c41d0c);
  background-color: #da220f;
  color: #fff; }

.block-dark {
  background: linear-gradient(to right, #4f525a, #373a3e);
  background-color: #43464c;
  color: #fff; }

.block-light {
  background: linear-gradient(to right, #e7ecf8, #bfccec);
  background-color: #d3dcf2;
  color: #212529; }

.block-accent-1 {
  background: linear-gradient(to right, #899fd6, #6381c8);
  background-color: #7690cf;
  color: #fff; }

.block-accent-2 {
  background: linear-gradient(to right, #4f525a, #373a3e);
  background-color: #43464c;
  color: #fff; }

.block-image-mainboard {
  background-image: url("../../../assets/bg-images/JumbotronBG.png"); }

.block-image-elegant {
  background-image: url("../../../assets/bg-images/charming-bg-elegant-xl.jpg");
  background-position: center; }
  @media screen and (min-width: 1921px) {
    .block-image-elegant {
      background-image: url("../../../assets/bg-images/charming-bg-elegant-xxl.jpg"); } }
  @media (max-width: 991.98px) {
    .block-image-elegant {
      background-image: url("../../../assets/bg-images/charming-bg-elegant-md.jpg"); } }
  @media (max-width: 575.98px) {
    .block-image-elegant {
      background-image: url("../../../assets/bg-images/charming-bg-elegant-xs.jpg"); } }

.block-image-nature {
  background-image: url("../../../assets/bg-images/charming-bg-nature-xl.jpg");
  background-position: center; }
  @media screen and (min-width: 1921px) {
    .block-image-nature {
      background-image: url("../../../assets/bg-images/charming-bg-nature-xxl.jpg"); } }
  @media (max-width: 991.98px) {
    .block-image-nature {
      background-image: url("../../../assets/bg-images/charming-bg-nature-md.jpg"); } }
  @media (max-width: 575.98px) {
    .block-image-nature {
      background-image: url("../../../assets/bg-images/charming-bg-nature-xs.jpg"); } }

.block-image-watercolor {
  background-image: url("../../../assets/bg-images/charming-bg-watercolor-xl.jpg");
  background-position: center; }
  @media screen and (min-width: 1921px) {
    .block-image-watercolor {
      background-image: url("../../../assets/bg-images/charming-bg-watercolor-xxl.jpg"); } }
  @media (max-width: 991.98px) {
    .block-image-watercolor {
      background-image: url("../../../assets/bg-images/charming-bg-watercolor-md.jpg"); } }
  @media (max-width: 575.98px) {
    .block-image-watercolor {
      background-image: url("../../../assets/bg-images/charming-bg-watercolor-xs.jpg"); } }

.block-image-workdesk {
  background-image: url("../../../assets/bg-images/charming-bg-workdesk-xl.jpg");
  background-position: center; }
  @media screen and (min-width: 1921px) {
    .block-image-workdesk {
      background-image: url("../../../assets/bg-images/charming-bg-workdesk-xxl.jpg"); } }
  @media (max-width: 991.98px) {
    .block-image-workdesk {
      background-image: url("../../../assets/bg-images/charming-bg-workdesk-md.jpg"); } }
  @media (max-width: 575.98px) {
    .block-image-workdesk {
      background-image: url("../../../assets/bg-images/charming-bg-workdesk-xs.jpg"); } }

.block-image-lego {
  background-image: url("../../../assets/bg-images/charming-bg-lego-xl.jpg");
  background-position: center; }
  @media screen and (min-width: 1921px) {
    .block-image-lego {
      background-image: url("../../../assets/bg-images/charming-bg-lego-xxl.jpg"); } }
  @media (max-width: 991.98px) {
    .block-image-lego {
      background-image: url("../../../assets/bg-images/charming-bg-lego-md.jpg"); } }
  @media (max-width: 575.98px) {
    .block-image-lego {
      background-image: url("../../../assets/bg-images/charming-bg-lego-xs.jpg"); } }

.block-image-cupcakes {
  background-image: url("../../../assets/bg-images/charming-bg-cupcakes-xl.jpg");
  background-position: center; }
  @media screen and (min-width: 1921px) {
    .block-image-cupcakes {
      background-image: url("../../../assets/bg-images/charming-bg-cupcakes-xxl.jpg"); } }
  @media (max-width: 991.98px) {
    .block-image-cupcakes {
      background-image: url("../../../assets/bg-images/charming-bg-cupcakes-md.jpg"); } }
  @media (max-width: 575.98px) {
    .block-image-cupcakes {
      background-image: url("../../../assets/bg-images/charming-bg-cupcakes-xs.jpg"); } }

.block-image-spices {
  background-image: url("../../../assets/bg-images/charming-bg-spices-xl.jpg");
  background-position: center; }
  @media screen and (min-width: 1921px) {
    .block-image-spices {
      background-image: url("../../../assets/bg-images/charming-bg-spices-xxl.jpg"); } }
  @media (max-width: 991.98px) {
    .block-image-spices {
      background-image: url("../../../assets/bg-images/charming-bg-spices-md.jpg"); } }
  @media (max-width: 575.98px) {
    .block-image-spices {
      background-image: url("../../../assets/bg-images/charming-bg-spices-xs.jpg"); } }

.block-image-jets {
  background-image: url("../../../assets/bg-images/charming-bg-jets-xl.jpg");
  background-position: center; }
  @media screen and (min-width: 1921px) {
    .block-image-jets {
      background-image: url("../../../assets/bg-images/charming-bg-jets-xxl.jpg"); } }
  @media (max-width: 991.98px) {
    .block-image-jets {
      background-image: url("../../../assets/bg-images/charming-bg-jets-md.jpg"); } }
  @media (max-width: 575.98px) {
    .block-image-jets {
      background-image: url("../../../assets/bg-images/charming-bg-jets-xs.jpg"); } }

.block-image-gokart {
  background-image: url("../../../assets/bg-images/charming-bg-gokart-xl.jpg");
  background-position: center; }
  @media screen and (min-width: 1921px) {
    .block-image-gokart {
      background-image: url("../../../assets/bg-images/charming-bg-gokart-xxl.jpg"); } }
  @media (max-width: 991.98px) {
    .block-image-gokart {
      background-image: url("../../../assets/bg-images/charming-bg-gokart-md.jpg"); } }
  @media (max-width: 575.98px) {
    .block-image-gokart {
      background-image: url("../../../assets/bg-images/charming-bg-gokart-xs.jpg"); } }

.block-image-flowers {
  background-image: url("../../../assets/bg-images/charming-bg-flowers-xl.jpg");
  background-position: center; }
  @media screen and (min-width: 1921px) {
    .block-image-flowers {
      background-image: url("../../../assets/bg-images/charming-bg-flowers-xxl.jpg"); } }
  @media (max-width: 991.98px) {
    .block-image-flowers {
      background-image: url("../../../assets/bg-images/charming-bg-flowers-md.jpg"); } }
  @media (max-width: 575.98px) {
    .block-image-flowers {
      background-image: url("../../../assets/bg-images/charming-bg-flowers-xs.jpg"); } }

.block-callout {
  background-color: rgba(157, 162, 186, 0.5);
  padding: 1.5rem; }

.block-sm {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem; }
  @media (min-width: 576px) {
    .block-sm {
      padding-bottom: 1.5rem;
      padding-top: 1.5rem; } }

.block-lg {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem; }
  @media (min-width: 576px) {
    .block-lg {
      padding-bottom: 4.5rem;
      padding-top: 4.5rem; } }

.box {
  border-radius: 5px;
  box-shadow: 4px 4px 9px 4px rgba(0, 0, 0, 0.02);
  color: #373a3c; }

.box-head {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3);
  border-radius: 5px 5px 0 0;
  display: block;
  min-height: 7px;
  width: 100%; }
  .box-head:not(:empty) {
    border: transparent 1px solid;
    padding: 1.125rem 1.5rem; }
    @media (min-width: 768px) {
      .box-head:not(:empty) {
        padding: 1.125rem 2.25rem; } }
  .box-head h1,
  .box-head h2,
  .box-head h3,
  .box-head h4,
  .box-head h5,
  .box-head h6 {
    margin-bottom: 0; }

.box-head-padded:not(:empty) {
  padding-left: 0.75rem;
  padding-right: 0.75rem; }
  @media (min-width: 576px) {
    .box-head-padded:not(:empty) {
      padding-left: 1.5rem;
      padding-right: 1.5rem; } }
  @media (min-width: 768px) {
    .box-head-padded:not(:empty) {
      padding-left: 4.5rem;
      padding-right: 4.5rem; } }

.box-head-sm {
  min-height: 5px; }
  .box-head-sm:not(:empty) {
    padding: 0.75rem 1.5rem; }
    @media (min-width: 768px) {
      .box-head-sm:not(:empty) {
        padding: 0.75rem 2.25rem; } }

.box-head-lg {
  min-height: 16px; }
  .box-head-lg:not(:empty) {
    padding: 1.875rem 1.5rem; }
    @media (min-width: 768px) {
      .box-head-lg:not(:empty) {
        padding: 1.875rem 2.25rem; } }

.box-block {
  background-color: #fafafa;
  padding: 0.75rem 1.5rem; }
  @media (min-width: 768px) {
    .box-block {
      padding: 1.125rem 2.25rem; } }
  .box-block:last-of-type {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; }

.box-block-padded {
  padding: 0.75rem; }
  @media (min-width: 576px) {
    .box-block-padded {
      padding: 1.5rem; } }
  @media (min-width: 768px) {
    .box-block-padded {
      padding: 2.25rem 4.5rem; } }

.box-block-codesample {
  background: #2e2e2e;
  color: #fff;
  font-family: 'Ubuntu Mono', monospace; }
  .box-block-codesample code,
  .box-block-codesample figure,
  .box-block-codesample pre {
    margin-bottom: 0; }
  .box-b .box-block-codesample {
    margin-left: -1px;
    margin-right: -1px; }

.box-table {
  margin-bottom: 0; }
  .box-table thead th {
    border-bottom: 1px solid #d5d5d5;
    border-top: 0;
    padding-bottom: 1.125rem;
    padding-top: 1.125rem; }
  .box-table td,
  .box-table th {
    border-top: 1px solid #e2e2e2; }
  .box-table.table .table-td-select select {
    background-color: transparent; }

.box-comment-info {
  align-items: center;
  display: flex; }

.box-comment-author {
  font-size: 1.25rem; }

.box-banner img {
  width: 100%; }

.box-block-teaser-title {
  font-weight: bold;
  text-transform: uppercase; }
  @media (max-width: 767.98px) {
    .box-block-teaser-title {
      font-size: 20px; } }
  .box-block-teaser-title a,
  .box-block-teaser-title a:focus,
  .box-block-teaser-title a:hover,
  .box-block-teaser-title a:active {
    color: #373a3c;
    text-decoration: none; }

.box-block-teaser-subtitle {
  font-style: italic;
  margin-bottom: 0.75rem; }
  @media (min-width: 768px) {
    .box-block-teaser-subtitle {
      margin-bottom: 1.5rem; } }

.box-block-teaser-text {
  margin-top: 0.75rem; }
  @media (min-width: 768px) {
    .box-block-teaser-text {
      margin-top: 1.5rem; } }

.box-block-teaser-sub {
  align-items: center;
  border-top: 1px solid #c8c8c8;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: -0.75rem;
  margin-top: 1.5rem;
  min-height: 4.5rem; }
  @media (min-width: 768px) {
    .box-block-teaser-sub {
      margin-bottom: -1.125rem;
      min-height: 5.25rem; } }
  .box-block-teaser-sub > * {
    margin-bottom: 0.75rem;
    margin-top: 0.75rem; }
  .box-block-padded .box-block-teaser-sub {
    margin-bottom: -0.75rem; }
    @media (min-width: 576px) {
      .box-block-padded .box-block-teaser-sub {
        margin-bottom: -1.5rem; } }
    @media (min-width: 768px) {
      .box-block-padded .box-block-teaser-sub {
        margin-bottom: -2.25rem; } }

.box-block-actions {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding-bottom: 1.125rem;
  padding-top: 1.125rem; }

.box-head-primary {
  background: linear-gradient(45deg, #51628d, #3f4c6d);
  background-color: #51628d;
  border-color: #5b6e9d;
  color: #fff; }
  .box-head-primary .blog-header-title-huge {
    color: #d1d5df; }
  .box-head-primary:not(:empty) {
    border-color: #5b6e9d; }

.box-head-secondary {
  background: linear-gradient(45deg, #9fa4b1, #838a9b);
  background-color: #9fa4b1;
  border-color: #adb2bd;
  color: #212529; }
  .box-head-secondary .blog-header-title-huge {
    color: #3d4248; }
  .box-head-secondary:not(:empty) {
    border-color: #adb2bd; }

.box-head-success {
  background: linear-gradient(45deg, #20b93d, #188d2f);
  background-color: #20b93d;
  border-color: #23cf44;
  color: #fff; }
  .box-head-success .blog-header-title-huge {
    color: #c6e8cd; }
  .box-head-success:not(:empty) {
    border-color: #23cf44; }

.box-head-info {
  background: linear-gradient(45deg, #a4d6f7, #76c2f3);
  background-color: #a4d6f7;
  border-color: #bce1f9;
  color: #212529; }
  .box-head-info .blog-header-title-huge {
    color: #3c4f5c; }
  .box-head-info:not(:empty) {
    border-color: #bce1f9; }

.box-head-warning {
  background: linear-gradient(45deg, #f6ab57, #f49327);
  background-color: #f6ab57;
  border-color: #f8b76f;
  color: #212529; }
  .box-head-warning .blog-header-title-huge {
    color: #56442f; }
  .box-head-warning:not(:empty) {
    border-color: #f8b76f; }

.box-head-danger {
  background: linear-gradient(45deg, #f12712, #c41d0c);
  background-color: #f12712;
  border-color: #f23d2a;
  color: #fff; }
  .box-head-danger .blog-header-title-huge {
    color: #f6c8c3; }
  .box-head-danger:not(:empty) {
    border-color: #f23d2a; }

.box-head-dark {
  background: linear-gradient(45deg, #4f525a, #373a3e);
  background-color: #4f525a;
  border-color: #5b5f67;
  color: #fff; }
  .box-head-dark .blog-header-title-huge {
    color: #d0d1d2; }
  .box-head-dark:not(:empty) {
    border-color: #5b5f67; }

.box-head-light {
  background: linear-gradient(45deg, #e7ecf8, #bfccec);
  background-color: #e7ecf8;
  border-color: #acbde6;
  color: #212529; }
  .box-head-light .blog-header-title-huge {
    color: #4e535b; }
  .box-head-light:not(:empty) {
    border-color: #acbde6; }

.box-head-accent-1 {
  background: linear-gradient(45deg, #899fd6, #6381c8);
  background-color: #899fd6;
  border-color: #9cafdc;
  color: #fff; }
  .box-head-accent-1 .blog-header-title-huge {
    color: #dde3f3; }
  .box-head-accent-1:not(:empty) {
    border-color: #9cafdc; }

.box-head-accent-2 {
  background: linear-gradient(45deg, #4f525a, #373a3e);
  background-color: #4f525a;
  border-color: #5b5f67;
  color: #fff; }
  .box-head-accent-2 .blog-header-title-huge {
    color: #d0d1d2; }
  .box-head-accent-2:not(:empty) {
    border-color: #5b5f67; }

.col-vstretch {
  align-items: stretch;
  display: flex;
  flex-direction: column; }

.box-vstretch {
  display: flex;
  flex-flow: column;
  flex-grow: 1; }

.box-block-vstretch {
  flex-grow: 1; }

.box-b {
  border: 1px solid #c8c8c8 !important; }

.box-bt {
  border-top: 1px solid #c8c8c8 !important; }

.box-br {
  border-right: 1px solid #c8c8c8 !important; }

.box-bb {
  border-bottom: 1px solid #c8c8c8 !important; }

.box-bl {
  border-left: 1px solid #c8c8c8 !important; }

.box-bx {
  border-left: 1px solid #c8c8c8 !important;
  border-right: 1px solid #c8c8c8 !important; }

.box-by {
  border-bottom: 1px solid #c8c8c8 !important;
  border-top: 1px solid #c8c8c8 !important; }

.box-b-0 {
  border: 0 !important; }

.box-bt-0 {
  border-top: 0 !important; }

.box-br-0 {
  border-right: 0 !important; }

.box-bb-0 {
  border-bottom: 0 !important; }

.box-bl-0 {
  border-left: 0 !important; }

.box-bx-0 {
  border-left: 0 !important;
  border-right: 0 !important; }

.box-by-0 {
  border-bottom: 0 !important;
  border-top: 0 !important; }

.box-rounded-0 {
  border-radius: 0 !important; }

.box-rounded {
  border-radius: 5px !important; }

.box-rounded-bottom {
  border-radius: 0 0 5px 5px !important; }

.box-rounded-top {
  border-radius: 5px 5px 0 0 !important; }

.blog-header-title-small {
  display: block;
  font-size: 1.5625rem;
  margin-bottom: 0.37594rem; }
  @media (min-width: 992px) {
    .blog-header-title-small {
      margin-bottom: 0.75rem; } }

.blog-header-title-huge {
  color: #a4a9bc;
  font-size: 2.4375rem;
  font-weight: 900;
  text-transform: uppercase; }
  @media (min-width: 992px) {
    .blog-header-title-huge {
      font-size: 4.75rem;
      line-height: 4.9875rem; } }

.blog-header-byline {
  display: block;
  font-style: italic;
  margin: 0.37594rem 0; }
  @media (min-width: 992px) {
    .blog-header-byline {
      margin: 1.5rem 0 0.37594rem; } }

.blog-header-author {
  display: block;
  margin: 0.37594rem 0;
  text-transform: uppercase; }
  @media (min-width: 992px) {
    .blog-header-author {
      margin: 0.37594rem 0 1.5rem; } }

.blog-header-social {
  line-height: 29px; }
  .blog-header-social a,
  .blog-header-social a:focus,
  .blog-header-social a:hover,
  .blog-header-social a:active {
    color: #fff;
    font-size: 29px;
    margin: 1.5rem 0;
    text-transform: none; }

.blog-subtitle {
  font-weight: 900;
  margin: 1.5rem 0;
  text-transform: uppercase; }

.blog-comments-title {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase; }

.dash {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 100%; }
  .dash:not(.dash-boxed) {
    min-height: 100vh; }

.dash-app {
  background-color: #f6f6f6;
  flex: 1;
  vertical-align: top; }
  @media (max-width: 991.98px) {
    .dash-app.hide {
      display: none; } }

.dash-nav {
  display: none;
  flex: 0 0 206px;
  flex-direction: column;
  overflow: auto;
  position: relative; }
  @media (min-width: 992px) {
    .dash-nav {
      display: flex; } }
  @media (max-width: 991.98px) {
    .dash-nav.show {
      bottom: 0;
      display: flex;
      flex-basis: 100%;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 1060; } }
  .dash-nav header {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    height: 72px;
    padding: 1.125rem;
    position: relative; }

.dash-nav-primary {
  background: #48577D; }
  .dash-nav-primary header {
    color: #fff; }
  .dash-nav-primary .dash-nav-logo-link::before {
    background-color: #d1d5df; }
  .dash-nav-primary .dash-nav-logo a {
    color: #d1d5df; }
  .dash-nav-primary .dash-nav-item {
    color: #fff; }
    .dash-nav-primary .dash-nav-item.active {
      background-color: #5a688a; }
    .dash-nav-primary .dash-nav-item:hover, .dash-nav-primary .dash-nav-item:active, .dash-nav-primary .dash-nav-item:focus {
      background-color: #5a688a;
      border-color: #d1d5df;
      color: #fff; }
  .dash-nav-primary .dash-nav-dropdown .dash-nav-item.active {
    box-shadow: none; }
  .dash-nav-primary .dash-nav-dropdown:hover {
    border-color: #c2c7d5; }
  .dash-nav-primary .dash-nav-dropdown.show {
    background-color: #5a688a;
    border-color: #d1d5df; }
    .dash-nav-primary .dash-nav-dropdown.show > .dash-nav-dropdown-toggle {
      background-color: #6d7997; }
  .dash-nav-primary .dash-nav-tools a {
    color: #fff; }

.dash-toolbar-primary {
  background: #48577D;
  color: #fff; }
  .dash-toolbar-primary .dash-toolbar-dropdown .dropdown-toggle,
  .dash-toolbar-primary .dash-toolbar-dropdown .dropdown-toggle:hover,
  .dash-toolbar-primary .dash-toolbar-dropdown .dropdown-toggle:active,
  .dash-toolbar-primary .dash-toolbar-dropdown .dropdown-toggle:focus {
    color: #fff; }
  .dash-toolbar-primary .dash-toolbar-input {
    background-color: #515f84;
    color: #fff; }
    .dash-toolbar-primary .dash-toolbar-input::placeholder {
      color: rgba(255, 255, 255, 0.6); }

.dash-nav-secondary {
  background: #9197A6; }
  .dash-nav-secondary header {
    color: #212529; }
  .dash-nav-secondary .dash-nav-logo-link::before {
    background-color: #3d4248; }
  .dash-nav-secondary .dash-nav-logo a {
    color: #3d4248; }
  .dash-nav-secondary .dash-nav-item {
    color: #212529; }
    .dash-nav-secondary .dash-nav-item.active {
      background-color: #9ca1af; }
    .dash-nav-secondary .dash-nav-item:hover, .dash-nav-secondary .dash-nav-item:active, .dash-nav-secondary .dash-nav-item:focus {
      background-color: #9ca1af;
      border-color: #3d4248;
      color: #212529; }
  .dash-nav-secondary .dash-nav-dropdown .dash-nav-item.active {
    box-shadow: none; }
  .dash-nav-secondary .dash-nav-dropdown:hover {
    border-color: #31353a; }
  .dash-nav-secondary .dash-nav-dropdown.show {
    background-color: #9ca1af;
    border-color: #3d4248; }
    .dash-nav-secondary .dash-nav-dropdown.show > .dash-nav-dropdown-toggle {
      background-color: #a7acb8; }
  .dash-nav-secondary .dash-nav-tools a {
    color: #212529; }

.dash-toolbar-secondary {
  background: #9197A6;
  color: #212529; }
  .dash-toolbar-secondary .dash-toolbar-dropdown .dropdown-toggle,
  .dash-toolbar-secondary .dash-toolbar-dropdown .dropdown-toggle:hover,
  .dash-toolbar-secondary .dash-toolbar-dropdown .dropdown-toggle:active,
  .dash-toolbar-secondary .dash-toolbar-dropdown .dropdown-toggle:focus {
    color: #212529; }
  .dash-toolbar-secondary .dash-toolbar-input {
    background-color: #979caa;
    color: #212529; }
    .dash-toolbar-secondary .dash-toolbar-input::placeholder {
      color: rgba(33, 37, 41, 0.6); }

.dash-nav-success {
  background: #1CA336; }
  .dash-nav-success header {
    color: #fff; }
  .dash-nav-success .dash-nav-logo-link::before {
    background-color: #c6e8cd; }
  .dash-nav-success .dash-nav-logo a {
    color: #c6e8cd; }
  .dash-nav-success .dash-nav-item {
    color: #fff; }
    .dash-nav-success .dash-nav-item.active {
      background-color: #33ac4a; }
    .dash-nav-success .dash-nav-item:hover, .dash-nav-success .dash-nav-item:active, .dash-nav-success .dash-nav-item:focus {
      background-color: #33ac4a;
      border-color: #c6e8cd;
      color: #fff; }
  .dash-nav-success .dash-nav-dropdown .dash-nav-item.active {
    box-shadow: none; }
  .dash-nav-success .dash-nav-dropdown:hover {
    border-color: #b4e1bd; }
  .dash-nav-success .dash-nav-dropdown.show {
    background-color: #33ac4a;
    border-color: #c6e8cd; }
    .dash-nav-success .dash-nav-dropdown.show > .dash-nav-dropdown-toggle {
      background-color: #49b55e; }
  .dash-nav-success .dash-nav-tools a {
    color: #fff; }

.dash-toolbar-success {
  background: #1CA336;
  color: #fff; }
  .dash-toolbar-success .dash-toolbar-dropdown .dropdown-toggle,
  .dash-toolbar-success .dash-toolbar-dropdown .dropdown-toggle:hover,
  .dash-toolbar-success .dash-toolbar-dropdown .dropdown-toggle:active,
  .dash-toolbar-success .dash-toolbar-dropdown .dropdown-toggle:focus {
    color: #fff; }
  .dash-toolbar-success .dash-toolbar-input {
    background-color: #27a840;
    color: #fff; }
    .dash-toolbar-success .dash-toolbar-input::placeholder {
      color: rgba(255, 255, 255, 0.6); }

.dash-nav-info {
  background: #8DCCF5; }
  .dash-nav-info header {
    color: #212529; }
  .dash-nav-info .dash-nav-logo-link::before {
    background-color: #3c4f5c; }
  .dash-nav-info .dash-nav-logo a {
    color: #3c4f5c; }
  .dash-nav-info .dash-nav-item {
    color: #212529; }
    .dash-nav-info .dash-nav-item.active {
      background-color: #98d1f6; }
    .dash-nav-info .dash-nav-item:hover, .dash-nav-info .dash-nav-item:active, .dash-nav-info .dash-nav-item:focus {
      background-color: #98d1f6;
      border-color: #3c4f5c;
      color: #212529; }
  .dash-nav-info .dash-nav-dropdown .dash-nav-item.active {
    box-shadow: none; }
  .dash-nav-info .dash-nav-dropdown:hover {
    border-color: #32424d; }
  .dash-nav-info .dash-nav-dropdown.show {
    background-color: #98d1f6;
    border-color: #3c4f5c; }
    .dash-nav-info .dash-nav-dropdown.show > .dash-nav-dropdown-toggle {
      background-color: #a4d6f7; }
  .dash-nav-info .dash-nav-tools a {
    color: #212529; }

.dash-toolbar-info {
  background: #8DCCF5;
  color: #212529; }
  .dash-toolbar-info .dash-toolbar-dropdown .dropdown-toggle,
  .dash-toolbar-info .dash-toolbar-dropdown .dropdown-toggle:hover,
  .dash-toolbar-info .dash-toolbar-dropdown .dropdown-toggle:active,
  .dash-toolbar-info .dash-toolbar-dropdown .dropdown-toggle:focus {
    color: #212529; }
  .dash-toolbar-info .dash-toolbar-input {
    background-color: #93cff6;
    color: #212529; }
    .dash-toolbar-info .dash-toolbar-input::placeholder {
      color: rgba(33, 37, 41, 0.6); }

.dash-nav-warning {
  background: #F59F3F; }
  .dash-nav-warning header {
    color: #212529; }
  .dash-nav-warning .dash-nav-logo-link::before {
    background-color: #56442f; }
  .dash-nav-warning .dash-nav-logo a {
    color: #56442f; }
  .dash-nav-warning .dash-nav-item {
    color: #212529; }
    .dash-nav-warning .dash-nav-item.active {
      background-color: #f6a952; }
    .dash-nav-warning .dash-nav-item:hover, .dash-nav-warning .dash-nav-item:active, .dash-nav-warning .dash-nav-item:focus {
      background-color: #f6a952;
      border-color: #56442f;
      color: #212529; }
  .dash-nav-warning .dash-nav-dropdown .dash-nav-item.active {
    box-shadow: none; }
  .dash-nav-warning .dash-nav-dropdown:hover {
    border-color: #463726; }
  .dash-nav-warning .dash-nav-dropdown.show {
    background-color: #f6a952;
    border-color: #56442f; }
    .dash-nav-warning .dash-nav-dropdown.show > .dash-nav-dropdown-toggle {
      background-color: #f7b265; }
  .dash-nav-warning .dash-nav-tools a {
    color: #212529; }

.dash-toolbar-warning {
  background: #F59F3F;
  color: #212529; }
  .dash-toolbar-warning .dash-toolbar-dropdown .dropdown-toggle,
  .dash-toolbar-warning .dash-toolbar-dropdown .dropdown-toggle:hover,
  .dash-toolbar-warning .dash-toolbar-dropdown .dropdown-toggle:active,
  .dash-toolbar-warning .dash-toolbar-dropdown .dropdown-toggle:focus {
    color: #212529; }
  .dash-toolbar-warning .dash-toolbar-input {
    background-color: #f6a449;
    color: #212529; }
    .dash-toolbar-warning .dash-toolbar-input::placeholder {
      color: rgba(33, 37, 41, 0.6); }

.dash-nav-danger {
  background: #DC210D; }
  .dash-nav-danger header {
    color: #fff; }
  .dash-nav-danger .dash-nav-logo-link::before {
    background-color: #f6c8c3; }
  .dash-nav-danger .dash-nav-logo a {
    color: #f6c8c3; }
  .dash-nav-danger .dash-nav-item {
    color: #fff; }
    .dash-nav-danger .dash-nav-item.active {
      background-color: #e03725; }
    .dash-nav-danger .dash-nav-item:hover, .dash-nav-danger .dash-nav-item:active, .dash-nav-danger .dash-nav-item:focus {
      background-color: #e03725;
      border-color: #f6c8c3;
      color: #fff; }
  .dash-nav-danger .dash-nav-dropdown .dash-nav-item.active {
    box-shadow: none; }
  .dash-nav-danger .dash-nav-dropdown:hover {
    border-color: #f3b4ad; }
  .dash-nav-danger .dash-nav-dropdown.show {
    background-color: #e03725;
    border-color: #f6c8c3; }
    .dash-nav-danger .dash-nav-dropdown.show > .dash-nav-dropdown-toggle {
      background-color: #e34d3d; }
  .dash-nav-danger .dash-nav-tools a {
    color: #fff; }

.dash-toolbar-danger {
  background: #DC210D;
  color: #fff; }
  .dash-toolbar-danger .dash-toolbar-dropdown .dropdown-toggle,
  .dash-toolbar-danger .dash-toolbar-dropdown .dropdown-toggle:hover,
  .dash-toolbar-danger .dash-toolbar-dropdown .dropdown-toggle:active,
  .dash-toolbar-danger .dash-toolbar-dropdown .dropdown-toggle:focus {
    color: #fff; }
  .dash-toolbar-danger .dash-toolbar-input {
    background-color: #de2c19;
    color: #fff; }
    .dash-toolbar-danger .dash-toolbar-input::placeholder {
      color: rgba(255, 255, 255, 0.6); }

.dash-nav-dark {
  background: #43464C; }
  .dash-nav-dark header {
    color: #fff; }
  .dash-nav-dark .dash-nav-logo-link::before {
    background-color: #d0d1d2; }
  .dash-nav-dark .dash-nav-logo a {
    color: #d0d1d2; }
  .dash-nav-dark .dash-nav-item {
    color: #fff; }
    .dash-nav-dark .dash-nav-item.active {
      background-color: #56595e; }
    .dash-nav-dark .dash-nav-item:hover, .dash-nav-dark .dash-nav-item:active, .dash-nav-dark .dash-nav-item:focus {
      background-color: #56595e;
      border-color: #d0d1d2;
      color: #fff; }
  .dash-nav-dark .dash-nav-dropdown .dash-nav-item.active {
    box-shadow: none; }
  .dash-nav-dark .dash-nav-dropdown:hover {
    border-color: #c3c4c6; }
  .dash-nav-dark .dash-nav-dropdown.show {
    background-color: #56595e;
    border-color: #d0d1d2; }
    .dash-nav-dark .dash-nav-dropdown.show > .dash-nav-dropdown-toggle {
      background-color: #696b70; }
  .dash-nav-dark .dash-nav-tools a {
    color: #fff; }

.dash-toolbar-dark {
  background: #43464C;
  color: #fff; }
  .dash-toolbar-dark .dash-toolbar-dropdown .dropdown-toggle,
  .dash-toolbar-dark .dash-toolbar-dropdown .dropdown-toggle:hover,
  .dash-toolbar-dark .dash-toolbar-dropdown .dropdown-toggle:active,
  .dash-toolbar-dark .dash-toolbar-dropdown .dropdown-toggle:focus {
    color: #fff; }
  .dash-toolbar-dark .dash-toolbar-input {
    background-color: #4c4f55;
    color: #fff; }
    .dash-toolbar-dark .dash-toolbar-input::placeholder {
      color: rgba(255, 255, 255, 0.6); }

.dash-nav-light {
  background: #D3DCF2; }
  .dash-nav-light header {
    color: #212529; }
  .dash-nav-light .dash-nav-logo-link::before {
    background-color: #4e535b; }
  .dash-nav-light .dash-nav-logo a {
    color: #4e535b; }
  .dash-nav-light .dash-nav-item {
    color: #212529; }
    .dash-nav-light .dash-nav-item.active {
      background-color: #d7e0f3; }
    .dash-nav-light .dash-nav-item:hover, .dash-nav-light .dash-nav-item:active, .dash-nav-light .dash-nav-item:focus {
      background-color: #d7e0f3;
      border-color: #4e535b;
      color: #212529; }
  .dash-nav-light .dash-nav-dropdown .dash-nav-item.active {
    box-shadow: none; }
  .dash-nav-light .dash-nav-dropdown:hover {
    border-color: #42464d; }
  .dash-nav-light .dash-nav-dropdown.show {
    background-color: #d7e0f3;
    border-color: #4e535b; }
    .dash-nav-light .dash-nav-dropdown.show > .dash-nav-dropdown-toggle {
      background-color: #dce3f5; }
  .dash-nav-light .dash-nav-tools a {
    color: #212529; }

.dash-toolbar-light {
  background: #D3DCF2;
  color: #212529; }
  .dash-toolbar-light .dash-toolbar-dropdown .dropdown-toggle,
  .dash-toolbar-light .dash-toolbar-dropdown .dropdown-toggle:hover,
  .dash-toolbar-light .dash-toolbar-dropdown .dropdown-toggle:active,
  .dash-toolbar-light .dash-toolbar-dropdown .dropdown-toggle:focus {
    color: #212529; }
  .dash-toolbar-light .dash-toolbar-input {
    background-color: #d5def3;
    color: #212529; }
    .dash-toolbar-light .dash-toolbar-input::placeholder {
      color: rgba(33, 37, 41, 0.6); }

.dash-nav-accent-1 {
  background: #7690CF; }
  .dash-nav-accent-1 header {
    color: #fff; }
  .dash-nav-accent-1 .dash-nav-logo-link::before {
    background-color: #dde3f3; }
  .dash-nav-accent-1 .dash-nav-logo a {
    color: #dde3f3; }
  .dash-nav-accent-1 .dash-nav-item {
    color: #fff; }
    .dash-nav-accent-1 .dash-nav-item.active {
      background-color: #849bd4; }
    .dash-nav-accent-1 .dash-nav-item:hover, .dash-nav-accent-1 .dash-nav-item:active, .dash-nav-accent-1 .dash-nav-item:focus {
      background-color: #849bd4;
      border-color: #dde3f3;
      color: #fff; }
  .dash-nav-accent-1 .dash-nav-dropdown .dash-nav-item.active {
    box-shadow: none; }
  .dash-nav-accent-1 .dash-nav-dropdown:hover {
    border-color: #cad3ec; }
  .dash-nav-accent-1 .dash-nav-dropdown.show {
    background-color: #849bd4;
    border-color: #dde3f3; }
    .dash-nav-accent-1 .dash-nav-dropdown.show > .dash-nav-dropdown-toggle {
      background-color: #91a6d9; }
  .dash-nav-accent-1 .dash-nav-tools a {
    color: #fff; }

.dash-toolbar-accent-1 {
  background: #7690CF;
  color: #fff; }
  .dash-toolbar-accent-1 .dash-toolbar-dropdown .dropdown-toggle,
  .dash-toolbar-accent-1 .dash-toolbar-dropdown .dropdown-toggle:hover,
  .dash-toolbar-accent-1 .dash-toolbar-dropdown .dropdown-toggle:active,
  .dash-toolbar-accent-1 .dash-toolbar-dropdown .dropdown-toggle:focus {
    color: #fff; }
  .dash-toolbar-accent-1 .dash-toolbar-input {
    background-color: #7d96d1;
    color: #fff; }
    .dash-toolbar-accent-1 .dash-toolbar-input::placeholder {
      color: rgba(255, 255, 255, 0.6); }

.dash-nav-accent-2 {
  background: #43464C; }
  .dash-nav-accent-2 header {
    color: #fff; }
  .dash-nav-accent-2 .dash-nav-logo-link::before {
    background-color: #d0d1d2; }
  .dash-nav-accent-2 .dash-nav-logo a {
    color: #d0d1d2; }
  .dash-nav-accent-2 .dash-nav-item {
    color: #fff; }
    .dash-nav-accent-2 .dash-nav-item.active {
      background-color: #56595e; }
    .dash-nav-accent-2 .dash-nav-item:hover, .dash-nav-accent-2 .dash-nav-item:active, .dash-nav-accent-2 .dash-nav-item:focus {
      background-color: #56595e;
      border-color: #d0d1d2;
      color: #fff; }
  .dash-nav-accent-2 .dash-nav-dropdown .dash-nav-item.active {
    box-shadow: none; }
  .dash-nav-accent-2 .dash-nav-dropdown:hover {
    border-color: #c3c4c6; }
  .dash-nav-accent-2 .dash-nav-dropdown.show {
    background-color: #56595e;
    border-color: #d0d1d2; }
    .dash-nav-accent-2 .dash-nav-dropdown.show > .dash-nav-dropdown-toggle {
      background-color: #696b70; }
  .dash-nav-accent-2 .dash-nav-tools a {
    color: #fff; }

.dash-toolbar-accent-2 {
  background: #43464C;
  color: #fff; }
  .dash-toolbar-accent-2 .dash-toolbar-dropdown .dropdown-toggle,
  .dash-toolbar-accent-2 .dash-toolbar-dropdown .dropdown-toggle:hover,
  .dash-toolbar-accent-2 .dash-toolbar-dropdown .dropdown-toggle:active,
  .dash-toolbar-accent-2 .dash-toolbar-dropdown .dropdown-toggle:focus {
    color: #fff; }
  .dash-toolbar-accent-2 .dash-toolbar-input {
    background-color: #4c4f55;
    color: #fff; }
    .dash-toolbar-accent-2 .dash-toolbar-input::placeholder {
      color: rgba(255, 255, 255, 0.6); }

.dash-nav-toggler {
  border-radius: 5px;
  align-items: center;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-shrink: 0;
  height: 36px;
  justify-content: center;
  padding: 0;
  width: 36px; }
  .dash-nav-toggler:hover, .dash-nav-toggler:focus {
    text-decoration: none; }
  @media (min-width: 992px) {
    .dash-nav-toggler {
      display: none; } }
  .dash-nav-toggler .dash-nav-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    display: inline-block;
    height: 1.5em;
    width: 1.5em; }

.dash-nav-logo {
  font-size: 20px;
  font-weight: bold; }
  @media (max-width: 991.98px) {
    .dash-nav-logo {
      margin-left: 0.75rem; } }
  .dash-nav-logo a,
  .dash-nav-logo a:hover,
  .dash-nav-logo a:active,
  .dash-nav-logo a:focus {
    text-decoration: none; }

.dash-nav-logo-link {
  position: relative; }
  .dash-nav-logo-link::before {
    bottom: -5px;
    box-shadow: 1px 1px rgba(0, 0, 0, 0.34);
    content: '';
    height: 2px;
    position: absolute;
    width: 30px; }

.dash-nav-tools {
  margin-top: auto;
  padding: 0.75rem; }
  .dash-nav-tools .icon {
    font-size: 25px; }
  .dash-nav-tools a,
  .dash-nav-tools a:hover,
  .dash-nav-tools a:active,
  .dash-nav-tools a:focus {
    text-decoration: none; }

.dash-nav-list {
  margin-top: 19px; }
  .dash-nav-list > .dash-nav-item {
    padding-left: 16px; }
    @media (max-width: 991.98px) {
      .dash-nav-list > .dash-nav-item {
        padding-left: 24px; } }
  .dash-nav-list > .dash-nav-dropdown {
    border-left: 4px solid transparent; }
    .dash-nav-list > .dash-nav-dropdown > .dash-nav-item {
      padding-left: 12px; }
      @media (max-width: 991.98px) {
        .dash-nav-list > .dash-nav-dropdown > .dash-nav-item {
          padding-left: 20px; } }

.dash-nav-item {
  align-items: center;
  display: flex;
  line-height: 24px;
  padding: 12px; }
  @media (max-width: 991.98px) {
    .dash-nav-item {
      padding: 16px 24px; } }
  .dash-nav-dropdown-menu .dash-nav-item {
    padding-bottom: 6px;
    padding-left: 52px;
    padding-top: 6px; }
  .dash-nav-dropdown-menu .dash-nav-dropdown-menu .dash-nav-item {
    padding-left: 62px; }
  .dash-nav-dropdown-menu .dash-nav-dropdown-menu .dash-nav-dropdown-menu .dash-nav-item {
    padding-left: 78px; }
  .dash-nav-item .icon {
    font-size: 24px;
    margin-right: 8px;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.34);
    vertical-align: middle;
    width: 27px; }
    .dash-nav-item .icon.fa {
      font-size: 20px; }
  .dash-nav-item .fa.fa-chevron-right {
    color: #c5c5c5;
    font-size: 11px;
    margin-left: auto; }
  .dash-nav-item .badge {
    margin-top: 5px; }
  .dash-nav-item:hover, .dash-nav-item:active, .dash-nav-item:focus {
    color: #d8d9dd;
    text-decoration: none; }
  .dash-nav-item.active {
    font-weight: bold; }

.dash-nav-dropdown.show {
  background-color: #2e323a; }
  .dash-nav-dropdown.show > .dash-nav-dropdown-menu {
    display: block; }
  .dash-nav-dropdown.show > .dash-nav-dropdown-toggle {
    background-color: #272b31; }
    .dash-nav-dropdown.show > .dash-nav-dropdown-toggle .fa.fa-chevron-right {
      transform: rotate(90deg); }

.dash-nav-dropdown-menu {
  display: none; }

.dash-nav-item-text {
  display: inline-block;
  vertical-align: middle; }

.dash-toolbar {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  min-height: 72px;
  padding: 1.125rem; }

.dash-toolbar-searchandnav {
  display: flex;
  flex-flow: row nowrap; }

.dash-toolbar-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1; }
  @media (min-width: 768px) {
    .dash-toolbar-wrapper {
      align-items: center;
      flex-direction: row; } }
  @media (max-width: 991.98px) {
    .dash-toolbar-wrapper {
      padding-left: 0.75rem; } }

.dash-toolbar-dropdown {
  flex-shrink: 0;
  margin-left: auto; }
  .dash-toolbar-dropdown .dropdown-toggle,
  .dash-toolbar-dropdown .dropdown-toggle:hover,
  .dash-toolbar-dropdown .dropdown-toggle:active,
  .dash-toolbar-dropdown .dropdown-toggle:focus {
    text-decoration: none; }

.dash-toolbar-form {
  display: inline-block;
  flex-shrink: 1;
  margin-right: 0.75rem; }
  @media (max-width: 991.98px) {
    .dash-toolbar-form {
      flex-grow: 1;
      margin-left: 0.75rem; } }

.dash-toolbar-input {
  border: 0;
  border-radius: 100px;
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.26);
  height: 2.25rem;
  padding-left: 20px;
  width: 100%; }
  @media (min-width: 768px) {
    .dash-toolbar-input {
      width: 300px; } }

.dash-titlebar {
  align-items: stretch;
  background-color: #fff;
  border-bottom: 1px solid #c8c8c8;
  color: #373a3c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 72px;
  padding: 0.75rem 1.5rem; }
  @media (min-width: 768px) {
    .dash-titlebar {
      align-items: center;
      flex-direction: row;
      justify-content: flex-start; } }

.dash-titlebar-title {
  font-size: 20px; }

.dash-titlebar-badges {
  font-size: 14px;
  margin-left: 0.75rem; }
  .dash-titlebar-badges .badge {
    font-size: 14px; }

.badge-transparent {
  color: #373a3c; }

.dash-boxes {
  padding: 0.75rem 1.5rem; }
  .dash-boxes .box {
    margin-bottom: 0.75rem;
    margin-top: 0.75rem; }

@media screen and (min-width: 1400px) {
  .dash-boxed {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
    margin-bottom: 100px;
    margin-top: 100px;
    max-width: 1366px; }
    .dash-boxed .dash-app {
      border-radius: 0 5px 5px 0; }
    .dash-boxed .dash-nav {
      border-radius: 5px 0 0 5px; }
    .dash-boxed .dash-nav-tools {
      border-radius: 0 0 0 5px; }
    .dash-boxed .dash-nav-logo {
      border-radius: 5px 0 0; }
    .dash-boxed .dash-boxes {
      border-radius: 0 0 5px; }
    .dash-boxed .dash-toolbar {
      border-radius: 0 5px 0 0; } }

/** Fixed navbars **/
@media (min-width: 992px) {
  .dash-fixed .dash-nav {
    bottom: 0;
    left: 0;
    position: fixed;
    top: 0;
    width: 206px;
    z-index: 999; } }

.dash-fixed .dash-toolbar {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999; }
  @media (min-width: 992px) {
    .dash-fixed .dash-toolbar {
      left: 206px; } }

.dash-fixed .dash-app main {
  margin-top: 72px; }
  @media (min-width: 992px) {
    .dash-fixed .dash-app main {
      margin-left: 206px; } }

.bg-gradient-dash {
  background: linear-gradient(to right, #c2cad1, #8e9eab);
  background-color: #a8b4be; }

.merits {
  padding: 48px 0; }
  .merits h1,
  .merits h2,
  .merits h3,
  .merits h4,
  .merits h5,
  .merits h6 {
    padding-bottom: 0.75rem;
    text-transform: uppercase; }

.merits-item {
  border-left: 1px solid transparent;
  min-height: 200px;
  padding: 30px 40px;
  padding-left: 40px; }
  @media (max-width: 1199.98px) {
    .merits-item {
      border-left: 0; } }
  .merits-item:first-child {
    border-left: 0; }
  .merits-item:last-child {
    border-right: 0; }

.merits-icon {
  display: inline-block;
  font-size: 75px;
  margin-bottom: 20px;
  margin-left: 0;
  margin-right: 0; }
  .merits-icon.fa {
    font-size: 50px; }

.merits-primary {
  background: linear-gradient(to right, #51628d, #3f4c6d);
  background-color: #48577d;
  color: #fff; }
  .merits-primary .merits-merit {
    border-color: #5b6e9d; }
  .merits-primary .merits-icon {
    color: #d1d5df; }
  .merits-primary .merits-item {
    border-color: #5b6e9d; }

.merits-secondary {
  background: linear-gradient(to right, #9fa4b1, #838a9b);
  background-color: #9197a6;
  color: #212529; }
  .merits-secondary .merits-merit {
    border-color: #adb2bd; }
  .merits-secondary .merits-icon {
    color: #3d4248; }
  .merits-secondary .merits-item {
    border-color: #adb2bd; }

.merits-success {
  background: linear-gradient(to right, #20b93d, #188d2f);
  background-color: #1ca336;
  color: #fff; }
  .merits-success .merits-merit {
    border-color: #23cf44; }
  .merits-success .merits-icon {
    color: #c6e8cd; }
  .merits-success .merits-item {
    border-color: #23cf44; }

.merits-info {
  background: linear-gradient(to right, #a4d6f7, #76c2f3);
  background-color: #8dccf5;
  color: #212529; }
  .merits-info .merits-merit {
    border-color: #bce1f9; }
  .merits-info .merits-icon {
    color: #3c4f5c; }
  .merits-info .merits-item {
    border-color: #bce1f9; }

.merits-warning {
  background: linear-gradient(to right, #f6ab57, #f49327);
  background-color: #f59f3f;
  color: #212529; }
  .merits-warning .merits-merit {
    border-color: #f8b76f; }
  .merits-warning .merits-icon {
    color: #56442f; }
  .merits-warning .merits-item {
    border-color: #f8b76f; }

.merits-danger {
  background: linear-gradient(to right, #f12712, #c41d0c);
  background-color: #da220f;
  color: #fff; }
  .merits-danger .merits-merit {
    border-color: #f23d2a; }
  .merits-danger .merits-icon {
    color: #f6c8c3; }
  .merits-danger .merits-item {
    border-color: #f23d2a; }

.merits-dark {
  background: linear-gradient(to right, #4f525a, #373a3e);
  background-color: #43464c;
  color: #fff; }
  .merits-dark .merits-merit {
    border-color: #5b5f67; }
  .merits-dark .merits-icon {
    color: #d0d1d2; }
  .merits-dark .merits-item {
    border-color: #5b5f67; }

.merits-light {
  background: linear-gradient(to right, #e7ecf8, #bfccec);
  background-color: #d3dcf2;
  color: #212529; }
  .merits-light .merits-merit {
    border-color: #acbde6; }
  .merits-light .merits-icon {
    color: #4e535b; }
  .merits-light .merits-item {
    border-color: #acbde6; }

.merits-accent-1 {
  background: linear-gradient(to right, #899fd6, #6381c8);
  background-color: #7690cf;
  color: #fff; }
  .merits-accent-1 .merits-merit {
    border-color: #9cafdc; }
  .merits-accent-1 .merits-icon {
    color: #dde3f3; }
  .merits-accent-1 .merits-item {
    border-color: #9cafdc; }

.merits-accent-2 {
  background: linear-gradient(to right, #4f525a, #373a3e);
  background-color: #43464c;
  color: #fff; }
  .merits-accent-2 .merits-merit {
    border-color: #5b5f67; }
  .merits-accent-2 .merits-icon {
    color: #d0d1d2; }
  .merits-accent-2 .merits-item {
    border-color: #5b5f67; }

.call-us {
  align-items: center;
  display: flex;
  flex-direction: column; }
  @media (min-width: 576px) {
    .call-us {
      flex-direction: row; } }

.call-us-avatar {
  flex-shrink: 0;
  margin-bottom: 2.25rem; }
  @media (min-width: 576px) {
    .call-us-avatar {
      margin-bottom: 0;
      margin-right: 2.25rem; } }

.team-member {
  margin-bottom: 3rem;
  text-align: center; }
  @media (min-width: 992px) {
    .team-member {
      margin-bottom: 0; } }

.team-avatar {
  border: 6px solid #c7c8cc;
  border-radius: 100px;
  box-shadow: 7px 7px 118px 14px rgba(45, 45, 45, 0.17); }

.team-member-text {
  margin-top: 0.75rem; }
  .team-member-text h3 {
    margin-bottom: 0; }

.skill {
  display: flex; }

.skill-icon {
  margin-right: 0.75rem; }

.logos {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around; }
  .logos img {
    margin: 1.5rem; }

.iconchunk {
  display: flex; }

.iconchunk-icon {
  color: #828282;
  display: inline-block; }
  .iconchunk-top .iconchunk-icon {
    border: 0;
    border-bottom: 2px solid #828282;
    margin-bottom: 0.75rem;
    padding-bottom: 0.37594rem; }
  .iconchunk-left .iconchunk-icon {
    border-right: 2px solid #828282;
    padding-right: 1rem 0.75; }
  .iconchunk-right .iconchunk-icon {
    border: 0;
    border-left: 2px solid #828282;
    padding-left: 1rem 0.75; }
  .iconchunk-left .iconchunk-icon,
  .iconchunk-right .iconchunk-icon {
    align-items: center;
    display: flex; }

.iconchunk-top {
  align-items: center;
  flex-direction: column; }

.iconchunk-left .iconchunk-content {
  padding-left: 0.75rem; }

.iconchunk-right .iconchunk-content {
  padding-right: 0.75rem; }

.iconchunk-primary .iconchunk-icon {
  border-color: #48577D; }

.iconchunk-secondary .iconchunk-icon {
  border-color: #9197A6; }

.iconchunk-success .iconchunk-icon {
  border-color: #1CA336; }

.iconchunk-info .iconchunk-icon {
  border-color: #8DCCF5; }

.iconchunk-warning .iconchunk-icon {
  border-color: #F59F3F; }

.iconchunk-danger .iconchunk-icon {
  border-color: #DC210D; }

.iconchunk-light .iconchunk-icon {
  border-color: #D3DCF2; }

.iconchunk-dark .iconchunk-icon {
  border-color: #43464C; }

.iconchunk-accent-1 .iconchunk-icon {
  border-color: #7690CF; }

.iconchunk-accent-2 .iconchunk-icon {
  border-color: #43464C; }

#google-map {
  border: 1px solid #cbcdd9;
  border-radius: 5px;
  height: 19.5rem;
  -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
  -webkit-transform: translate3d(0, 0, 0);
  width: 100%; }
  #google-map > div {
    border-radius: 5px; }

.ftr-oneline {
  padding: 1.5rem 0; }

.ftr-columns {
  padding-bottom: 4.5rem;
  padding-top: 4.5rem; }

.ftr-bb {
  border-bottom: 1px solid transparent; }

.ftr-bt {
  border-top: 1px solid transparent; }

.ftr-column-list {
  list-style: none;
  padding-left: 0;
  padding-top: 1.5rem; }

.ftr-primary {
  color: #fff;
  background-color: #48577D;
  border-color: #5b6e9d; }
  .ftr-primary .ftr-bb {
    border-color: #5b6e9d; }
  .ftr-primary .ftr-bt {
    border-color: #5b6e9d; }
  .ftr-primary .ftr-column-title {
    color: #d1d5df; }
  .ftr-primary a,
  .ftr-primary a:hover,
  .ftr-primary a:link {
    color: #fff; }
  .ftr-primary .ftr-oneline {
    color: #d1d5df; }
    .ftr-primary .ftr-oneline a,
    .ftr-primary .ftr-oneline a:hover,
    .ftr-primary .ftr-oneline a:link {
      color: #d1d5df; }

.ftr-secondary {
  color: #212529;
  background-color: #9197A6;
  border-color: #adb2bd; }
  .ftr-secondary .ftr-bb {
    border-color: #adb2bd; }
  .ftr-secondary .ftr-bt {
    border-color: #adb2bd; }
  .ftr-secondary .ftr-column-title {
    color: #3d4248; }
  .ftr-secondary a,
  .ftr-secondary a:hover,
  .ftr-secondary a:link {
    color: #212529; }
  .ftr-secondary .ftr-oneline {
    color: #3d4248; }
    .ftr-secondary .ftr-oneline a,
    .ftr-secondary .ftr-oneline a:hover,
    .ftr-secondary .ftr-oneline a:link {
      color: #3d4248; }

.ftr-success {
  color: #fff;
  background-color: #1CA336;
  border-color: #23cf44; }
  .ftr-success .ftr-bb {
    border-color: #23cf44; }
  .ftr-success .ftr-bt {
    border-color: #23cf44; }
  .ftr-success .ftr-column-title {
    color: #c6e8cd; }
  .ftr-success a,
  .ftr-success a:hover,
  .ftr-success a:link {
    color: #fff; }
  .ftr-success .ftr-oneline {
    color: #c6e8cd; }
    .ftr-success .ftr-oneline a,
    .ftr-success .ftr-oneline a:hover,
    .ftr-success .ftr-oneline a:link {
      color: #c6e8cd; }

.ftr-info {
  color: #212529;
  background-color: #8DCCF5;
  border-color: #bce1f9; }
  .ftr-info .ftr-bb {
    border-color: #bce1f9; }
  .ftr-info .ftr-bt {
    border-color: #bce1f9; }
  .ftr-info .ftr-column-title {
    color: #3c4f5c; }
  .ftr-info a,
  .ftr-info a:hover,
  .ftr-info a:link {
    color: #212529; }
  .ftr-info .ftr-oneline {
    color: #3c4f5c; }
    .ftr-info .ftr-oneline a,
    .ftr-info .ftr-oneline a:hover,
    .ftr-info .ftr-oneline a:link {
      color: #3c4f5c; }

.ftr-warning {
  color: #212529;
  background-color: #F59F3F;
  border-color: #f8b76f; }
  .ftr-warning .ftr-bb {
    border-color: #f8b76f; }
  .ftr-warning .ftr-bt {
    border-color: #f8b76f; }
  .ftr-warning .ftr-column-title {
    color: #56442f; }
  .ftr-warning a,
  .ftr-warning a:hover,
  .ftr-warning a:link {
    color: #212529; }
  .ftr-warning .ftr-oneline {
    color: #56442f; }
    .ftr-warning .ftr-oneline a,
    .ftr-warning .ftr-oneline a:hover,
    .ftr-warning .ftr-oneline a:link {
      color: #56442f; }

.ftr-danger {
  color: #fff;
  background-color: #DC210D;
  border-color: #f23d2a; }
  .ftr-danger .ftr-bb {
    border-color: #f23d2a; }
  .ftr-danger .ftr-bt {
    border-color: #f23d2a; }
  .ftr-danger .ftr-column-title {
    color: #f6c8c3; }
  .ftr-danger a,
  .ftr-danger a:hover,
  .ftr-danger a:link {
    color: #fff; }
  .ftr-danger .ftr-oneline {
    color: #f6c8c3; }
    .ftr-danger .ftr-oneline a,
    .ftr-danger .ftr-oneline a:hover,
    .ftr-danger .ftr-oneline a:link {
      color: #f6c8c3; }

.ftr-dark {
  color: #fff;
  background-color: #43464C;
  border-color: #5b5f67; }
  .ftr-dark .ftr-bb {
    border-color: #5b5f67; }
  .ftr-dark .ftr-bt {
    border-color: #5b5f67; }
  .ftr-dark .ftr-column-title {
    color: #d0d1d2; }
  .ftr-dark a,
  .ftr-dark a:hover,
  .ftr-dark a:link {
    color: #fff; }
  .ftr-dark .ftr-oneline {
    color: #d0d1d2; }
    .ftr-dark .ftr-oneline a,
    .ftr-dark .ftr-oneline a:hover,
    .ftr-dark .ftr-oneline a:link {
      color: #d0d1d2; }

.ftr-light {
  color: #212529;
  background-color: #D3DCF2;
  border-color: #acbde6; }
  .ftr-light .ftr-bb {
    border-color: #acbde6; }
  .ftr-light .ftr-bt {
    border-color: #acbde6; }
  .ftr-light .ftr-column-title {
    color: #4e535b; }
  .ftr-light a,
  .ftr-light a:hover,
  .ftr-light a:link {
    color: #212529; }
  .ftr-light .ftr-oneline {
    color: #4e535b; }
    .ftr-light .ftr-oneline a,
    .ftr-light .ftr-oneline a:hover,
    .ftr-light .ftr-oneline a:link {
      color: #4e535b; }

.ftr-accent-1 {
  color: #fff;
  background-color: #7690CF;
  border-color: #9cafdc; }
  .ftr-accent-1 .ftr-bb {
    border-color: #9cafdc; }
  .ftr-accent-1 .ftr-bt {
    border-color: #9cafdc; }
  .ftr-accent-1 .ftr-column-title {
    color: #dde3f3; }
  .ftr-accent-1 a,
  .ftr-accent-1 a:hover,
  .ftr-accent-1 a:link {
    color: #fff; }
  .ftr-accent-1 .ftr-oneline {
    color: #dde3f3; }
    .ftr-accent-1 .ftr-oneline a,
    .ftr-accent-1 .ftr-oneline a:hover,
    .ftr-accent-1 .ftr-oneline a:link {
      color: #dde3f3; }

.ftr-accent-2 {
  color: #fff;
  background-color: #43464C;
  border-color: #5b5f67; }
  .ftr-accent-2 .ftr-bb {
    border-color: #5b5f67; }
  .ftr-accent-2 .ftr-bt {
    border-color: #5b5f67; }
  .ftr-accent-2 .ftr-column-title {
    color: #d0d1d2; }
  .ftr-accent-2 a,
  .ftr-accent-2 a:hover,
  .ftr-accent-2 a:link {
    color: #fff; }
  .ftr-accent-2 .ftr-oneline {
    color: #d0d1d2; }
    .ftr-accent-2 .ftr-oneline a,
    .ftr-accent-2 .ftr-oneline a:hover,
    .ftr-accent-2 .ftr-oneline a:link {
      color: #d0d1d2; }

@media (min-width: 992px) {
  .web-nav {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    min-height: 70px; } }

@media (max-width: 991px) {
  .web-nav {
    display: block;
    min-height: 96px; }
    .web-nav header {
      align-items: center;
      background: #fff;
      box-shadow: 0 2px 7px 4px rgba(0, 0, 0, 0.05);
      display: flex;
      justify-content: flex-start;
      left: 0;
      min-height: 96px;
      padding: 0 24px;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 1030; }
    .web-nav.show {
      background: #43464C;
      bottom: 0;
      left: 0;
      overflow: auto;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 1030; }
      .web-nav.show header {
        position: relative;
        width: 100%; } }

.web-nav-toggler {
  margin-left: auto;
  padding: 20px 0 20px 20px; }
  .web-nav-toggler:hover {
    text-decoration: none; }
  .web-nav-toggler .fa {
    font-size: 30px; }
    @media (max-width: 350px) {
      .web-nav-toggler .fa {
        font-size: 20px; } }
  .web-nav-toggler .fa-close {
    display: none; }
  .web-nav-toggler.open .fa-bars {
    display: none; }
  .web-nav-toggler.open .fa-close {
    display: block; }
  @media (min-width: 992px) {
    .web-nav-toggler {
      display: none; } }

.web-nav-list {
  display: flex; }
  @media (max-width: 991px) {
    .web-nav-list {
      background: #fff;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
      display: none;
      flex-flow: column nowrap;
      margin: 24px; }
      .web-nav-list.show {
        display: flex; }
      .web-nav.show .web-nav-list {
        display: flex; }
      .web-nav-list > .web-nav-dropdown {
        border-left: 4px solid transparent; }
        .web-nav-list > .web-nav-dropdown.show {
          border-color: #7690CF; }
      .web-nav-list > .web-nav-item {
        border-left: 4px solid transparent; } }
  .web-nav-list a,
  .web-nav-list a:hover,
  .web-nav-list a:focus,
  .web-nav-list a:active {
    color: #373a3c;
    text-decoration: none; }
  .web-nav-list > .web-nav-item,
  .web-nav-list > .web-nav-dropdown > .web-nav-item {
    position: relative; }
    @media (min-width: 992px) {
      .web-nav-list > .web-nav-item,
      .web-nav-list > .web-nav-dropdown > .web-nav-item {
        padding: 23px 10px; }
        .web-nav-list > .web-nav-item .fa.fa-chevron-right,
        .web-nav-list > .web-nav-dropdown > .web-nav-item .fa.fa-chevron-right {
          transform: rotate(90deg); }
        .web-nav-list > .web-nav-item:hover,
        .web-nav-list > .web-nav-dropdown > .web-nav-item:hover {
          background: rgba(0, 0, 0, 0.06); } }
    @media (min-width: 992px) and (min-width: 992px) {
      .web-nav-light .web-nav-list > .web-nav-item:hover, .web-nav-light
      .web-nav-list > .web-nav-dropdown > .web-nav-item:hover {
        background: rgba(255, 255, 255, 0.1); } }
    .web-nav-list > .web-nav-item.active,
    .web-nav-list > .web-nav-dropdown > .web-nav-item.active {
      font-weight: bold; }
  .web-nav-list .web-nav-dropdown.show > .web-nav-item {
    background: rgba(0, 0, 0, 0.06); }
  @media (min-width: 992px) {
    .web-nav-light .web-nav-list > .web-nav-dropdown.show > .web-nav-item {
      background: rgba(255, 255, 255, 0.1); } }

.web-nav-item {
  padding: 16px 24px; }
  @media (min-width: 992px) {
    .web-nav-item {
      padding: 12px 24px; }
      .web-nav-item:hover {
        background: #f0f0f4; } }
  .web-nav-dropdown-menu .web-nav-item {
    border-left: 4px solid transparent;
    white-space: nowrap; }
    .web-nav-dropdown-menu .web-nav-item.active {
      font-weight: bold; }
    @media (min-width: 992px) {
      .web-nav-dropdown-menu .web-nav-item:hover {
        border-color: #7690CF; } }
    @media (min-width: 992px) {
      .web-nav-dropdown-menu .web-nav-item .fa {
        margin-left: 20px;
        margin-right: -10px; } }
    @media (max-width: 991px) {
      .web-nav-dropdown-menu .web-nav-item {
        position: relative; } }
  @media (max-width: 991px) {
    .web-nav-dropdown-menu .web-nav-item {
      padding-left: 32px; }
    .web-nav-dropdown-menu .web-nav-dropdown-menu .web-nav-item {
      padding-left: 48px; }
    .web-nav-dropdown-menu .web-nav-dropdown-menu .web-nav-dropdown-menu .web-nav-item {
      padding-left: 64px; }
    .web-nav-dropdown-menu .web-nav-dropdown-menu .web-nav-dropdown-menu .web-nav-dropdown-menu .web-nav-item {
      padding-left: 80px; } }

.web-nav-dropdown.show > .web-nav-dropdown-menu {
  display: flex; }

@media (max-width: 991px) {
  .web-nav-dropdown.show > .web-nav-dropdown-toggle .fa.fa-chevron-right {
    transform: rotate(90deg); } }

.web-nav-dropdown-toggle {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative; }
  .web-nav-dropdown-toggle .fa {
    color: #c5c5c5;
    font-size: 11px;
    margin-left: 8px; }
  .web-nav-dropdown .web-nav-dropdown .web-nav-dropdown-toggle {
    display: flex; }

.web-nav-dropdown-menu {
  display: none;
  flex-flow: column nowrap;
  z-index: 1030; }
  @media (min-width: 992px) {
    .web-nav-dropdown-menu {
      background: #fff;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
      position: absolute; } }

.web-nav-highlight-primary .web-nav-list > .web-nav-dropdown.show {
  border-color: #48577D; }

@media (min-width: 992px) {
  .web-nav-highlight-primary .web-nav-dropdown-menu .web-nav-item:hover {
    border-color: #48577D; } }

.web-nav-highlight-primary .web-nav-toggler {
  color: #48577D; }

.web-nav-highlight-secondary .web-nav-list > .web-nav-dropdown.show {
  border-color: #9197A6; }

@media (min-width: 992px) {
  .web-nav-highlight-secondary .web-nav-dropdown-menu .web-nav-item:hover {
    border-color: #9197A6; } }

.web-nav-highlight-secondary .web-nav-toggler {
  color: #9197A6; }

.web-nav-highlight-success .web-nav-list > .web-nav-dropdown.show {
  border-color: #1CA336; }

@media (min-width: 992px) {
  .web-nav-highlight-success .web-nav-dropdown-menu .web-nav-item:hover {
    border-color: #1CA336; } }

.web-nav-highlight-success .web-nav-toggler {
  color: #1CA336; }

.web-nav-highlight-info .web-nav-list > .web-nav-dropdown.show {
  border-color: #8DCCF5; }

@media (min-width: 992px) {
  .web-nav-highlight-info .web-nav-dropdown-menu .web-nav-item:hover {
    border-color: #8DCCF5; } }

.web-nav-highlight-info .web-nav-toggler {
  color: #8DCCF5; }

.web-nav-highlight-warning .web-nav-list > .web-nav-dropdown.show {
  border-color: #F59F3F; }

@media (min-width: 992px) {
  .web-nav-highlight-warning .web-nav-dropdown-menu .web-nav-item:hover {
    border-color: #F59F3F; } }

.web-nav-highlight-warning .web-nav-toggler {
  color: #F59F3F; }

.web-nav-highlight-danger .web-nav-list > .web-nav-dropdown.show {
  border-color: #DC210D; }

@media (min-width: 992px) {
  .web-nav-highlight-danger .web-nav-dropdown-menu .web-nav-item:hover {
    border-color: #DC210D; } }

.web-nav-highlight-danger .web-nav-toggler {
  color: #DC210D; }

.web-nav-highlight-light .web-nav-list > .web-nav-dropdown.show {
  border-color: #D3DCF2; }

@media (min-width: 992px) {
  .web-nav-highlight-light .web-nav-dropdown-menu .web-nav-item:hover {
    border-color: #D3DCF2; } }

.web-nav-highlight-light .web-nav-toggler {
  color: #D3DCF2; }

.web-nav-highlight-dark .web-nav-list > .web-nav-dropdown.show {
  border-color: #43464C; }

@media (min-width: 992px) {
  .web-nav-highlight-dark .web-nav-dropdown-menu .web-nav-item:hover {
    border-color: #43464C; } }

.web-nav-highlight-dark .web-nav-toggler {
  color: #43464C; }

.web-nav-highlight-accent-1 .web-nav-list > .web-nav-dropdown.show {
  border-color: #7690CF; }

@media (min-width: 992px) {
  .web-nav-highlight-accent-1 .web-nav-dropdown-menu .web-nav-item:hover {
    border-color: #7690CF; } }

.web-nav-highlight-accent-1 .web-nav-toggler {
  color: #7690CF; }

.web-nav-highlight-accent-2 .web-nav-list > .web-nav-dropdown.show {
  border-color: #43464C; }

@media (min-width: 992px) {
  .web-nav-highlight-accent-2 .web-nav-dropdown-menu .web-nav-item:hover {
    border-color: #43464C; } }

.web-nav-highlight-accent-2 .web-nav-toggler {
  color: #43464C; }

@media (min-width: 992px) {
  .web-nav-light .web-nav-list > .web-nav-item,
  .web-nav-light .web-nav-list > .web-nav-dropdown > .web-nav-item {
    color: #D3DCF2; } }

.charming-logo {
  color: #373a3c;
  display: block;
  font-size: 1.5625rem;
  font-weight: bold;
  line-height: 1.44; }
  @media (min-width: 992px) {
    .web-nav-light .charming-logo {
      color: #D3DCF2; }
      .web-nav-light .charming-logo:hover {
        color: #D3DCF2; } }
  .charming-logo .charming-logo-icon {
    font-size: 30px;
    margin-right: 5px; }
  @media (max-width: 350px) {
    .charming-logo {
      font-size: 1.25rem; }
      .charming-logo .charming-logo-icon {
        font-size: 20px; } }
  .charming-logo:hover, .charming-logo:focus, .charming-logo:active {
    color: #373a3c;
    text-decoration: none; }

#web-nav-mobile-indicator {
  display: none; }
  @media (max-width: 991px) {
    #web-nav-mobile-indicator {
      display: block; } }

.box-price {
  display: block;
  font-size: 2.4375rem;
  height: 72px;
  padding-top: 10px;
  text-align: center; }

.box-head-pricing {
  font-size: 1.5625rem;
  line-height: 2.25rem;
  min-height: 4.5rem;
  text-align: center; }

.box-price-superscript {
  display: inline-block;
  font-size: 1rem;
  margin-top: 11px;
  vertical-align: top; }

.box-price-subscript {
  font-size: 1rem; }

.box-list {
  list-style: none;
  margin-bottom: 0;
  padding-bottom: 1.125rem;
  padding-right: 20px; }
  .box-list li::before {
    border-color: transparent transparent transparent #d0d5d9;
    border-style: solid;
    border-width: 3px 8px;
    content: '';
    display: block;
    left: -20px;
    position: relative;
    top: 15px;
    width: 8px; }

.block-with-pricing {
  margin-bottom: 2.25rem; }
  @media (min-width: 1200px) {
    .block-with-pricing {
      margin-bottom: 0;
      padding-bottom: 7.5rem; } }

@media (min-width: 1200px) {
  .pricing-with-block {
    margin-top: -122px; } }

.box-balloon {
  position: relative; }

.box-balloon-triangle {
  background: #fafafa;
  border: 1px solid #c8c8c8;
  border-left: 0;
  border-top: 0;
  bottom: -10px;
  height: 20px;
  left: 3rem;
  position: absolute;
  transform: rotate(45deg);
  width: 20px; }

.balloon-author {
  margin-top: 1.5rem;
  padding-left: 2.25rem; }

.balloon-author-photo {
  float: left;
  height: 50px;
  position: relative;
  width: 50px; }
  .balloon-author-photo img {
    border-radius: 50px;
    height: 50px;
    width: 50px; }

.balloon-author-photo-shadow {
  border: 1px solid #a2a2a2;
  border-radius: 50px;
  box-shadow: inset 0 2px 0 0 255, 255, 255, 0.47;
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 50px; }

.balloon-author-name {
  font-weight: bold;
  margin-left: 60px; }

.balloon-author-position {
  margin-left: 60px; }

.table .table-td-select {
  padding: 5px 6px; }
  .table .table-td-select select {
    border: 0;
    padding-left: 2px; }

.table .table-col-checkbox {
  width: 30px; }

.table .table-td-progress .progress {
  margin-bottom: 5px;
  margin-top: 5px; }

.table .table-td-buttons {
  padding: 9px; }

.bg-gradient-primary {
  background: linear-gradient(to right, #51628d, #3f4c6d);
  background-color: #48577d; }

.bg-gradient-secondary {
  background: linear-gradient(to right, #9fa4b1, #838a9b);
  background-color: #9197a6; }

.bg-gradient-success {
  background: linear-gradient(to right, #20b93d, #188d2f);
  background-color: #1ca336; }

.bg-gradient-info {
  background: linear-gradient(to right, #a4d6f7, #76c2f3);
  background-color: #8dccf5; }

.bg-gradient-warning {
  background: linear-gradient(to right, #f6ab57, #f49327);
  background-color: #f59f3f; }

.bg-gradient-danger {
  background: linear-gradient(to right, #f12712, #c41d0c);
  background-color: #da220f; }

.bg-gradient-dark {
  background: linear-gradient(to right, #4f525a, #373a3e);
  background-color: #43464c; }

.bg-gradient-light {
  background: linear-gradient(to right, #e7ecf8, #bfccec);
  background-color: #d3dcf2; }

.bg-gradient-accent-1 {
  background: linear-gradient(to right, #899fd6, #6381c8);
  background-color: #7690cf; }

.bg-gradient-accent-2 {
  background: linear-gradient(to right, #4f525a, #373a3e);
  background-color: #43464c; }

.tbg-primary {
  background-color: rgba(72, 87, 125, 0.65) !important; }

.tbg-secondary {
  background-color: rgba(145, 151, 166, 0.65) !important; }

.tbg-success {
  background-color: rgba(28, 163, 54, 0.65) !important; }

.tbg-info {
  background-color: rgba(141, 204, 245, 0.65) !important; }

.tbg-warning {
  background-color: rgba(245, 159, 63, 0.65) !important; }

.tbg-danger {
  background-color: rgba(220, 33, 13, 0.65) !important; }

.tbg-light {
  background-color: rgba(211, 220, 242, 0.65) !important; }

.tbg-dark {
  background-color: rgba(67, 70, 76, 0.65) !important; }

.tbg-accent-1 {
  background-color: rgba(118, 144, 207, 0.65) !important; }

.tbg-accent-2 {
  background-color: rgba(67, 70, 76, 0.65) !important; }

.text-reduced-primary {
  color: #bfc4d2 !important; }

.text-reduced-secondary {
  color: #d9dbe0 !important; }

.text-reduced-success {
  color: #b0dfb9 !important; }

.text-reduced-info {
  color: #d7edfc !important; }

.text-reduced-warning {
  color: #fcddbc !important; }

.text-reduced-danger {
  color: #f3b1aa !important; }

.text-reduced-light {
  color: #f0f3fa !important; }

.text-reduced-dark {
  color: #bdbec0 !important; }

.text-reduced-accent-1 {
  color: #cfd8ee !important; }

.text-reduced-accent-2 {
  color: #bdbec0 !important; }
